import React, { useState } from "react";
import { Icon } from "../../elements/icon";
import { Modal } from "../../elements/Modal";
import PlusIcon from "../../../assets/focusareas/plus.png";
const NutrientsMobile = ({ heading, desc1, desc2, data }) => {
  return (
    <div className="md:t-hidden t-flex t-flex-col t-gap-4 t-p-4">
      <h1 className="t-text-[28px] t-m-0 t-text-center">{heading}</h1>
      <div className="t-flex t-flex-col t-gap-4 t-p-4">
        <p className="t-m-0 t-leading-4">{desc1}</p>
        <p className="t-m-0 t-leading-4">{desc2}</p>
      </div>
      <div className="t-flex t-flex-wrap t-items-stretch t-justify-center t-gap-4">
        {data.map((item) => (
          <IngredientItem
            title={item.title}
            desc={item.desc}
            img={item.img}
            details1={item.details1}
            details2={item.details2}
            key={"energy-ingredients-" + item.title}
          />
        ))}
      </div>
    </div>
  );
};

const IngredientItem = ({ title, desc, img, details1, details2 }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="t-relative t-w-[47%] t-flex t-flex-col t-items-center t-justify-start">
        <img src={img} alt="" className="t-w-20 t-h-20" />
        <p
          className="t-m-0 t-text-center t-font-[900] t-text-base"
          style={{ fontFamily: "Roboto" }}
        >
          {title}
        </p>
        <p className="t-m-0 t-text-center t-text-base">{desc}</p>
        <img
          src={PlusIcon}
          alt=""
          className="t-absolute t-top-2 t-right-8 t-z-10 t-h-4 t-w-4"
          onClick={() => setOpen(true)}
        />
      </div>
      <Modal
        open={open}
        handleClose={() => setOpen(false)}
        notCrossIcon
        className="!t-bg-gray-200 !t-border-0 !t-p-0 t-w-full t-max-w-[300px]"
      >
        <div className="t-relative t-flex t-flex-col t-items-start t-justify-start t-gap-2 t-p-4">
          <div className="t-flex t-items-center t-gap-2">
            <img src={img} alt="" className="t-h-10 t-w-10" />
            <p
              className="t-m-0 t-text-base t-text-secondary t-font-[900]"
              style={{ fontFamily: "Roboto" }}
            >
              {title}
            </p>
          </div>
          <p className="t-m-0 t-text-secondary t-leading-4 t-text-sm">
            {details1}
          </p>
          <p className="t-m-0 t-text-secondary t-leading-4 t-text-sm">
            {details2}
          </p>
          <Icon
            size="20"
            name="cross"
            color="#005d67"
            className="t-absolute t-right-4 t-top-5"
            onClick={() => setOpen(false)}
          />
        </div>
      </Modal>
    </>
  );
};

export default NutrientsMobile;
