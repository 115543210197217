import React from "react";
import { useTranslation } from "react-i18next";
import {
  SleepHeader1,
  SleepDescription,
  NutirentsBodyNeed,
  SleepNutrientsHeader,
  FormulatedByExperts,
  SleepVitaminB6,
  SleepVitaminB6Second,
  SleepZinc,
  SleepZincSecond,
  SleepMagnesium,
  SleepMagnesiumSecond,
  VitaminB6,
  VitaminB6Desc,
  Magnesium,
  MagnesiumDesc,
  Zinc,
  ZincDesc,
  SleepHeader,
  VitaminB9,
  VitaminB9Desc,
  SleepVitaminB9,
  SleepVitaminB9Second,
} from "../../Constants";
import ExpertSection from "../Customer/components/ExpertSection";
import MillionCombinations from "../Home/MillionCombinations";
import HighStandards from "../Home/HighStandards";
import ReviewsOverlay from "../Reviews";
import Unique from "../Home/Unique";
import MagnesiumImg from "../../assets/focusareas/magnesium-microgranules.webp";
import VitaminB6Img from "../../assets/focusareas/vitamin-B6-microgranules.webp";
import ZincImg from "../../assets/focusareas/zinc-microgranules.webp";
import VitaminB9Img from "../../assets/focusareas/vitamin-B9-microgranules.webp";
import BannerMobile from "./components/BannerMobile";
import Description from "./components/Description";
import NutrientsMobile from "./components/NutrientsMobile";
import News from "./components/News";
import GlutenFree from "./components/GlutenFree";
import Banner from "./components/Banner";
import Nutrients from "./components/Nutrients";
import UniqueWeb from "./components/UniqueWeb";
import GlutenFreeWeb from "./components/GlutenFreeWeb";

const Sleep = () => {
  const { t } = useTranslation();

  const INGREDIENTS_DATA = [
    {
      title: t(Magnesium),
      desc: t(MagnesiumDesc),
      img: MagnesiumImg,
      details1: t(SleepMagnesium),
      details2: t(SleepMagnesiumSecond),
    },
    {
      title: t(VitaminB6),
      desc: t(VitaminB6Desc),
      img: VitaminB6Img,
      details1: t(SleepVitaminB6),
      details2: t(SleepVitaminB6Second),
    },
    {
      title: t(VitaminB9),
      desc: t(VitaminB9Desc),
      img: VitaminB9Img,
      details1: t(SleepVitaminB9),
      details2: t(SleepVitaminB9Second),
    },
    {
      title: t(Zinc),
      desc: t(ZincDesc),
      img: ZincImg,
      details1: t(SleepZinc),
      details2: t(SleepZincSecond),
    },
  ];

  return (
    <div>
      <BannerMobile heading1={t(SleepHeader)} heading2={t(SleepHeader1)} />
      <div className="t-hidden md:t-block">
        <Banner
          heading1={t(SleepHeader)}
          heading2={t(SleepHeader1)}
          desc1={t(SleepDescription)}
          desc2=""
        />
        <Nutrients
          heading={t(NutirentsBodyNeed)}
          desc1={t(SleepNutrientsHeader)}
          desc2=""
          data={INGREDIENTS_DATA}
        />

        <UniqueWeb />
        <GlutenFreeWeb />
      </div>
      <Description desc1={t(SleepDescription)} desc2="" />
      <NutrientsMobile
        heading={t(NutirentsBodyNeed)}
        desc1={t(SleepNutrientsHeader)}
        desc2=""
        data={INGREDIENTS_DATA}
      />
      <div className="md:t-hidden">
        <Unique isFocusArea />
      </div>
      <div>
        <MillionCombinations slug="/quiz" isFocusArea />
      </div>
      <section className="md:t-hidden t-flex t-items-center t-justify-center t-py-2 t-bg-gray-100 t-h-10">
        <ReviewsOverlay totalReviews={55} averageReviews={4.92} />
      </section>
      <div className="t-flex t-flex-col t-gap-4 t-p-4 t-max-w-[800px] !t-mx-auto">
        <h1 className="t-text-[24px] t-text-center">
          {t(FormulatedByExperts)}
        </h1>
        <ExpertSection />
      </div>
      <div>
        <HighStandards />
      </div>
      <GlutenFree />
      <News />
    </div>
  );
};

export default Sleep;
