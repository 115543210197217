import React from "react";
import UniqueImage from "../../assets/images/home/UniqueFormula.webp";
import UniqueImageDe from "../../assets/images/home/UniqueFormulaDe.png";
import PouchImage from "../../assets/focusareas/pouch-in-hand.webp";
import VeganImg from "../../assets/vegan.webp";
import GlutenFreeImg from "../../assets/gluten-free.webp";
import NoArtificialColoringImg from "../../assets/no-additives.webp";
import SuitableForAthletesimg from "../../assets/suitable-for-athletes-doping-free.webp";
import FingerPrint from "../../assets/images/home/finger-print.webp";
import Granules from "../../assets/images/home/granules.webp";
import Arrows from "../../assets/images/home/arrows.webp";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from "react-i18next";
import {
  GlutenFree,
  NoArtificialCloning,
  SuitableForAthletes,
  SupportEnergyDesc1,
  SupportEnergyDesc2,
  UniqueHeading,
  UniqueSlide1Desc,
  UniqueSlide1Heading,
  UniqueSlide2Desc,
  UniqueSlide2Heading,
  UniqueSlide3Desc,
  UniqueSlide3Heading,
  Vegan,
} from "../../Constants";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../features/langSlice";

const Unique = ({ isFocusArea, heading }) => {
  const { t } = useTranslation();
  const selectedLang = useSelector(selectCurrentLanguage);

  const DATA = [
    { title: t(Vegan), img: VeganImg },
    { title: t(GlutenFree), img: GlutenFreeImg },
    { title: t(NoArtificialCloning), img: NoArtificialColoringImg },
    { title: t(SuitableForAthletes), img: SuitableForAthletesimg },
  ];

  const SLIDER_DATA = [
    {
      img: FingerPrint,
      title: t(UniqueSlide1Heading),
      description: t(UniqueSlide1Desc),
    },
    {
      img: Granules,
      title: t(UniqueSlide2Heading),
      description: t(UniqueSlide2Desc),
    },
    {
      img: Arrows,
      title: t(UniqueSlide3Heading),
      description: t(UniqueSlide3Desc),
    },
  ];

  return (
    <div className="t-flex t-flex-col t-w-[100vw] t-bg-[#29a999]">
      <div className="t-flex t-flex-col t-items-center t-gap-4 t-px-4 t-pt-4 t-max-w-[600px] t-mx-auto">
        <h2
          className={`${
            isFocusArea && "t-text-white"
          } t-text-[24px] md:t-text-[30px] t-text-center`}
        >
          {heading ? heading : t(UniqueHeading)}
        </h2>
        {isFocusArea && (
          <div className="t-flex t-flex-col t-gap-4 -t-mt-2">
            <p className="t-m-0 t-leading-4 t-text-white">
              {t(SupportEnergyDesc1)}
            </p>
            <p className="t-m-0 t-leading-4 t-text-white">
              {t(SupportEnergyDesc2)}
            </p>
          </div>
        )}
        <div className="t-flex t-flex-col t-gap-2">
          {/* <Carousel
            showArrows={false}
            showIndicators={true}
            showThumbs={false}
            showStatus={false}
            preventMovementUntilSwipeScrollTolerance={true}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              return (
                <li
                  className={`dot ${isSelected ? "selected" : ""}`}
                  style={{
                    display: "inline-block",
                    margin: "0 5px",
                    cursor: "pointer",
                    width: 10,
                    height: 10,
                    background: isSelected ? "#A6A6A6" : "#aaa",
                    borderRadius: "50%",
                    boxShadow: "0 0 0 0",
                  }}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  role="button"
                  tabIndex={0}
                  aria-label={`Slide ${index + 1} ${label}`}
                />
              );
            }}
          > */}
          {SLIDER_DATA.map((item) => (
            <div
              className="t-mx-auto t-max-w-[90%]"
              key={"unique-slider-" + item.title}
            >
              <div className="t-w-full t-flex t-flex-col t-gap-1 t-bg-gray-100 t-rounded-lg t-p-4 t-overflow-hidden">
                <div className="t-flex t-items-center t-overflow-hidden t-w-full t-gap-1">
                  <img
                    src={item.img}
                    alt=""
                    className="t-flex-none !t-h-[10%] !t-w-[10%] sm:!t-h-[7%] sm:!t-w-[7%] t-object-cover"
                  />
                  <h5
                    className="t-m-0 t-text-secondary t-text-left !t-font-bold"
                    style={{ fontFamily: "Roboto", boxSizing: "border-box" }}
                  >
                    {item.title}
                  </h5>
                </div>
                <p className="t-m-0 t-text-left t-text-secondary">
                  {item.description}
                </p>
              </div>
            </div>
          ))}
          {/* </Carousel> */}
        </div>

        <img
          src={
            isFocusArea
              ? PouchImage
              : selectedLang === "de"
              ? UniqueImageDe
              : UniqueImage
          }
          alt=""
          className={`${isFocusArea && "t-w-[80%] -t-ml-16"} `}
        />
      </div>
      {!isFocusArea && (
        <div className="t-flex t-items-start t-justify-between t-p-4 t-bg-gray-200">
          <div className="t-flex t-items-start t-justify-between t-p-4 sm:t-gap-4 t-max-w-[800px] t-mx-auto">
            {DATA.map((item) => (
              <div
                className="t-flex-none t-w-1/4 t-flex t-flex-col t-items-center t-justify-center t-gap-1"
                key={"unique-" + item.title}
              >
                <img src={item.img} alt="" height={40} width={40} />
                <p className="t-m-0 t-text-center t-text-secondary t-leading-4">
                  {item.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Unique;
