import React from "react";
import { useTranslation } from "react-i18next";
import {
  MoodHeader1,
  NutirentsBodyNeed,
  MoodNutrientsHeader,
  FormulatedByExperts,
  MoodMagnesium,
  MoodMagnesiumSecond,
  Magnesium,
  MagnesiumDesc,
  MoodHeader,
  MoodDescription,
  MoodNutrientsHeader1,
  MoodVitaminD,
  MoodVitaminDSecond,
  MoodVitaminB9,
  MoodVitaminB9Second,
  MoodVitaminB12,
  MoodVitaminB12Second,
  MoodDescription1,
  VitaminD,
  VitaminDDesc,
  VitaminB9,
  VitaminB9Desc,
  VitaminB12,
  VitaminB12Desc,
  SupportMood,
} from "../../Constants";
import ExpertSection from "../Customer/components/ExpertSection";
import MillionCombinations from "../Home/MillionCombinations";
import HighStandards from "../Home/HighStandards";
import ReviewsOverlay from "../Reviews";
import Unique from "../Home/Unique";
import MagnesiumImg from "../../assets/focusareas/magnesium-microgranules.webp";
import VitaminDImg from "../../assets/focusareas/vitamin-D3-microgranules.webp";
import VitaminB12Img from "../../assets/focusareas/vitamin-b12.png";
import VitaminB9Img from "../../assets/focusareas/vitamin-B9-microgranules.webp";
import BannerMobile from "./components/BannerMobile";
import Description from "./components/Description";
import NutrientsMobile from "./components/NutrientsMobile";
import News from "./components/News";
import GlutenFree from "./components/GlutenFree";
import Banner from "./components/Banner";
import Nutrients from "./components/Nutrients";
import UniqueWeb from "./components/UniqueWeb";
import GlutenFreeWeb from "./components/GlutenFreeWeb";

const Mood = () => {
  const { t } = useTranslation();

  const INGREDIENTS_DATA = [
    {
      title: t(Magnesium),
      desc: t(MagnesiumDesc),
      img: MagnesiumImg,
      details1: t(MoodMagnesium),
      details2: t(MoodMagnesiumSecond),
    },
    {
      title: t(VitaminD),
      desc: t(VitaminDDesc),
      img: VitaminDImg,
      details1: t(MoodVitaminD),
      details2: t(MoodVitaminDSecond),
    },
    {
      title: t(VitaminB9),
      desc: t(VitaminB9Desc),
      img: VitaminB9Img,
      details1: t(MoodVitaminB9),
      details2: t(MoodVitaminB9Second),
    },
    {
      title: t(VitaminB12),
      desc: t(VitaminB12Desc),
      img: VitaminB12Img,
      details1: t(MoodVitaminB12),
      details2: t(MoodVitaminB12Second),
    },
  ];

  return (
    <div>
      <BannerMobile heading1={t(MoodHeader)} heading2={t(MoodHeader1)} />
      <div className="t-hidden md:t-block">
        <Banner
          heading1={t(MoodHeader)}
          heading2={t(MoodHeader1)}
          desc1={t(MoodDescription)}
          desc2={t(MoodDescription1)}
        />
        <Nutrients
          heading={t(NutirentsBodyNeed)}
          desc1={t(MoodNutrientsHeader)}
          desc2={t(MoodNutrientsHeader1)}
          data={INGREDIENTS_DATA}
        />

        <UniqueWeb />
        <GlutenFreeWeb />
      </div>
      <Description desc1={t(MoodDescription)} desc2={t(MoodDescription1)} />
      <NutrientsMobile
        heading={t(NutirentsBodyNeed)}
        desc1={t(MoodNutrientsHeader)}
        desc2={t(MoodNutrientsHeader1)}
        data={INGREDIENTS_DATA}
      />
      <div className="md:t-hidden">
        <Unique isFocusArea heading={t(SupportMood)} />
      </div>
      <div>
        <MillionCombinations slug="/quiz" isFocusArea />
      </div>
      <section className="md:t-hidden t-flex t-items-center t-justify-center t-py-2 t-bg-gray-100 t-h-10">
        <ReviewsOverlay totalReviews={55} averageReviews={4.92} />
      </section>
      <div className="t-flex t-flex-col t-gap-4 t-p-4 t-max-w-[800px] !t-mx-auto">
        <h1 className="t-text-[24px] t-text-center">
          {t(FormulatedByExperts)}
        </h1>
        <ExpertSection />
      </div>
      <div>
        <HighStandards />
      </div>
      <GlutenFree />
      <News />
    </div>
  );
};

export default Mood;
