import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

const LanguageEnforcer = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const language = localStorage.getItem("lang") || "en";

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has("lang")) {
      searchParams.set("lang", language === "us" ? "en" : language);
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  }, [location, navigate, language]);

  return children;
};

export default LanguageEnforcer;
