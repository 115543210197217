import React from "react";
import { useTranslation } from "react-i18next";
import {
  SportsPerformanceHeader,
  SportsPerformanceHeader1,
  SportsPerformanceIntro,
  NutirentsBodyNeed,
  SportsPerformanceSupport,
  FormulatedByExperts,
  SportsPerformanceIron,
  SportsPerformanceIronSecond,
  SportsPerformanceZinc,
  SportsPerformanceZincSecond,
  SportsPerformanceB_Vitamins,
  SportsPerformanceB_VitaminsSecond,
  Magnesium,
  MagnesiumDesc,
  SportsPerformanceMagnesium,
  SportsPerformanceMagnesiumSecond,
  Iron,
  IronDesc,
  BVitamins,
  BVitaminsDesc,
  Zinc,
  ZincDesc,
  SupportSportPerformance,
} from "../../Constants";
import ExpertSection from "../Customer/components/ExpertSection";
import MillionCombinations from "../Home/MillionCombinations";
import HighStandards from "../Home/HighStandards";
import ReviewsOverlay from "../Reviews";
import Unique from "../Home/Unique";
import MagnesiumImg from "../../assets/focusareas/magnesium-microgranules.webp";
import IronImg from "../../assets/focusareas/iron-microgranules.webp";
import ZincImg from "../../assets/focusareas/zinc-microgranules.webp";
import BVitaminsImg from "../../assets/focusareas/b-vitamins.png";
import BannerMobile from "./components/BannerMobile";
import Description from "./components/Description";
import NutrientsMobile from "./components/NutrientsMobile";
import News from "./components/News";
import GlutenFree from "./components/GlutenFree";
import Banner from "./components/Banner";
import Nutrients from "./components/Nutrients";
import UniqueWeb from "./components/UniqueWeb";
import GlutenFreeWeb from "./components/GlutenFreeWeb";

const SportsPerformance = () => {
  const { t } = useTranslation();

  const INGREDIENTS_DATA = [
    {
      title: t(Magnesium),
      desc: t(MagnesiumDesc),
      img: MagnesiumImg,
      details1: t(SportsPerformanceMagnesium),
      details2: t(SportsPerformanceMagnesiumSecond),
    },
    {
      title: t(BVitamins),
      desc: t(BVitaminsDesc),
      img: BVitaminsImg,
      details1: t(SportsPerformanceB_Vitamins),
      details2: t(SportsPerformanceB_VitaminsSecond),
    },
    {
      title: t(Iron),
      desc: t(IronDesc),
      img: IronImg,
      details1: t(SportsPerformanceIron),
      details2: t(SportsPerformanceIronSecond),
    },

    {
      title: t(Zinc),
      desc: t(ZincDesc),
      img: ZincImg,
      details1: t(SportsPerformanceZinc),
      details2: t(SportsPerformanceZincSecond),
    },
  ];

  return (
    <div>
      <BannerMobile
        heading1={t(SportsPerformanceHeader)}
        heading2={t(SportsPerformanceHeader1)}
      />
      <div className="t-hidden md:t-block">
        <Banner
          heading1={t(SportsPerformanceHeader)}
          heading2={t(SportsPerformanceHeader1)}
          desc1={t(SportsPerformanceIntro)}
          desc2=""
        />
        <Nutrients
          heading={t(NutirentsBodyNeed)}
          desc1={t(SportsPerformanceSupport)}
          desc2=""
          data={INGREDIENTS_DATA}
        />

        <UniqueWeb />
        <GlutenFreeWeb />
      </div>
      <Description desc1={t(SportsPerformanceIntro)} desc2="" />
      <NutrientsMobile
        heading={t(NutirentsBodyNeed)}
        desc1={t(SportsPerformanceSupport)}
        desc2=""
        data={INGREDIENTS_DATA}
      />
      <div className="md:t-hidden">
        <Unique isFocusArea heading={t(SupportSportPerformance)} />
      </div>
      <div>
        <MillionCombinations slug="/quiz" isFocusArea />
      </div>
      <section className="md:t-hidden t-flex t-items-center t-justify-center t-py-2 t-bg-gray-100 t-h-10">
        <ReviewsOverlay totalReviews={55} averageReviews={4.92} />
      </section>
      <div className="t-flex t-flex-col t-gap-4 t-p-4 t-max-w-[800px] !t-mx-auto">
        <h1 className="t-text-[24px] t-text-center">
          {t(FormulatedByExperts)}
        </h1>
        <ExpertSection />
      </div>
      <div>
        <HighStandards />
      </div>
      <GlutenFree />
      <News />
    </div>
  );
};

export default SportsPerformance;
