import React from "react";
import LifeLab from "../../assets/lifelab.svg";
import Carrotcare from "../../assets/carrot-care.png";
import Milano from "../../assets/milano.png";
import Hpl from "../../assets/hpl.png";
import { useTranslation } from "react-i18next";
import { PartnersHeading } from "../../Constants";

const IMAGES = [LifeLab, Carrotcare, Milano, Hpl];

const Partners = () => {
  const { t } = useTranslation();
  return (
    <div className="t-p-4 t-flex t-flex-col t-gap-4 t-items-center">
      <h1 className="t-text-secondary t-text-[24px] md:t-text-[30px]">
        {t(PartnersHeading)}
      </h1>
      <div className="t-flex t-items-center t-justify-between t-gap-8">
        {IMAGES.map((item) => (
          <img src={item} alt="" className="t-h-16 t-w-16 t-flex-non" />
        ))}
      </div>
    </div>
  );
};

export default Partners;
