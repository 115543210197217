import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { logo, logo_home, cart, user } from "../../Data/Images/Home";
import Language from "./Language";
import {
  selectTakeTheTestTitle,
  selectSlug,
  setCookieBannerMessage,
  setTakeTheTest,
  setSlug,
} from "../../features/contentsSlice";
import { useSelector } from "react-redux";

import { Login, Ingredients } from "../../Constants";
import { getProfileActions } from "../../app/api/useDataManage/userActions";

import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGetContentsByTagWebQuery } from "../../features/contentsApiSlice";
import { selectCurrentLanguage } from "../../features/langSlice";
import { Icon } from "../../Components/elements/icon";
import DashboardMenu from "../../Components/Customer/components/DashboardMenu";

const Header = () => {
  const location = useLocation();
  const selectedLang = useSelector(selectCurrentLanguage);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [freeShippingOrders, setFreeShippingOrders] = useState("");
  const takeTheTestTitle = useSelector(selectTakeTheTestTitle);
  const slug = useSelector(selectSlug);

  const [lang, setLang] = useState();
  const [skip, setSkip] = useState(false);

  const [token, setToken] = useState(localStorage.getItem("customer_token"));
  const quiz_id = localStorage.getItem("quiz_id");
  const userData = useSelector((state) => state.user);

  const navigate = useNavigate();

  const navigateToIngredients = () => {
    toggleMenu2();
    navigate("/ingredients");
  };

  const {
    data: fetchContents,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetContentsByTagWebQuery({ tag: "home", lang: lang, skip: skip });

  useEffect(() => {
    setLang(selectedLang === "us" ? "en" : selectedLang);

    refetch();
  }, [refetch, lang, selectedLang]);

  useEffect(() => {
    if (isSuccess) {
      //console.log(fetchContents.data)

      if (fetchContents.data.length > 0) {
        // this message used in header
        setFreeShippingOrders(fetchContents.data[22].title);

        dispatch(
          setCookieBannerMessage({ message: fetchContents.data[23].contents })
        );
        dispatch(setTakeTheTest({ title: fetchContents.data[24].title }));
        dispatch(setSlug({ slug: fetchContents.data[24].slug }));
      }
    }
  }, [fetchContents]);

  // { isAuthenticated, user }
  useEffect(() => {
    if (token && token !== "") {
      dispatch(getProfileActions(token));
    }
  }, [token, dispatch, userData.isAuthenticated]);

  const toggleMenu = (e) => {
    e.preventDefault();
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleMenu2 = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <header className={`sticky-top ${isMenuOpen ? " sidebar-active" : ""}`}>
        <nav className="navbar navbar-expand-xl navbar-light">
          <div className="container">
            <div className="d-flex justify-content-between w-100 align-items-center d-xl-none">
              <Link to={"/"}>
                <img src={logo} alt="logo riise" width={80} />
              </Link>

              <div className="d-flex">
                <div className="mobile:!t-hidden">
                  <Link to={slug} className="sm:!t-hidden ">
                    <span className="btn btn-custom !t-rounded-lg">
                      {takeTheTestTitle}
                    </span>
                  </Link>
                </div>
                <div className="d-sm-flex d-none">
                  {quiz_id && !token && (
                    <div className="t-relative">
                      <Link className="cart" to="/product-suggestion">
                        <img
                          src={cart}
                          alt=""
                          className="img-fluid"
                          width={33}
                        />
                      </Link>
                      <div className="!t-h-3 !t-w-3 !t-rounded-full t-bg-red-500 t-absolute -t-right-1 t-top-0" />
                    </div>
                  )}

                  {userData.isAuthenticated === true && token !== "" ? (
                    <Link className="login" to={"/dashboard"}>
                      <img src={user} alt="" className="img-fluid" width={24} />
                      <span>{t(userData?.user?.name)}</span>
                    </Link>
                  ) : (
                    <Link className="login" to={"/login"}>
                      <img src={user} alt="" className="img-fluid" width={24} />
                      <span>{t(Login)}</span>
                    </Link>
                  )}
                </div>
                {/* {userData.isAuthenticated === true && token !== '' ? ( */}
                {/* <button
                  className="lg:t-hidden navbar-toggler border-0 ms-3"
                  onClick={(e) => toggleMenu(e)}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsibleNavId"
                  aria-controls="collapsibleNavId"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="fa fa-list-ul fs-4 text-white" />
                </button> */}
                <Icon
                  name="menu"
                  color="#fff"
                  onClick={(e) => toggleMenu(e)}
                  className="lg:t-hidden navbar-toggler border-0 ms-3"
                />
                {/* ) : (
                  <>
                  <Link className='cart small:!t-hidden' to={`/contactus`}>
                    <img src={cart} alt='' width={33} />
                  </Link>
                  <Link className='login small:!t-hidden' to={`/contactus`}>
                      <img src={user} alt='' className='img-fluid' width={24} />
                      <span>{t(Login)}</span>
                    </Link>
                  </>
                )} */}
              </div>
            </div>
            <div
              className="collapse navbar-collapse d-xl-flex d-none"
              id="collapsibleNavId"
            >
              <ul className="navbar-nav mt-2 mt-lg-0 me-1 col-xl-4">
                <li className="nav-item text-center">
                  <Link to={slug}>
                    <span className="btn btn-custom !t-rounded-lg">
                      {takeTheTestTitle}
                    </span>
                  </Link>
                </li>
                <li className="nav-item text-center">
                  <button
                    className="btn me-xl-3 mb-3 mb-xl-0 text-white "
                    style={{ textTransform: "none", border: "none" }}
                    onClick={navigateToIngredients}
                  >
                    {t(Ingredients)}
                  </button>
                </li>
              </ul>
              <ul className="navbar-nav mx-auto mt-2 mt-lg-0 d-xl-flex d-none">
                <li className="nav-item">
                  <Link to={`/`}>
                    <img src={logo} alt="" className="img-fluid" width={80} />
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav mt-2 mt-lg-0 d-xl-flex d-none align-items-center col-xl-4 justify-content-end">
                {quiz_id && !token && (
                  <li className="!t-relative t-border-r-[1px] t-border-primary t-pr-4">
                    <Link className="cart" to="/product-suggestion">
                      <img src={cart} alt="" className="img-fluid" width={33} />
                    </Link>
                    <div className="!t-h-3 !t-w-3 !t-rounded-full t-bg-red-500 t-absolute t-right-3 t-top-0" />
                  </li>
                )}

                <li className="nav-item t-pl-6">
                  {userData.isAuthenticated === true && token !== "" ? (
                    <Link
                      className="t-flex t-items-center t-gap-1"
                      to={"/dashboard"}
                    >
                      <img src={user} alt="" className="img-fluid" width={24} />
                      <span className="t-text-primary">
                        {t(userData?.user?.name)}
                      </span>
                    </Link>
                  ) : (
                    <Link
                      className="t-flex t-items-center t-gap-1"
                      to={`/login`}
                    >
                      <img src={user} alt="" className="img-fluid" width={24} />
                      <span className="t-text-primary">{t(Login)}</span>
                    </Link>
                  )}
                </li>
                <Language />
              </ul>
            </div>
          </div>
        </nav>
        <div className="mobile-overlay" />
        <div className="mobile-sidebar t-flex t-flex-col t-py-8 md:t-py-16">
          {/* <a href="#" className="sidebar-close" onClick={(e) => toggleMenu(e)}><i className="fa fa-times" /></a> */}
          <Icon
            name="cross"
            color="#fff"
            size="16"
            className="t-absolute t-top-2 t-right-2 t-flex t-items-center t-justify-center t-p-1 t-bg-secondary t-rounded-full"
            onClick={(e) => toggleMenu(e)}
          />
          <Link to="/">
            <img src={logo_home} alt="" className="t-mx-auto" />
          </Link>

          <DashboardMenu setIsMenuOpen={setIsMenuOpen} />
          <div className="!t-absolute t-bottom-4 t-px-4">
            <Language isSidebar />
          </div>
          {/* <ul className="">
              <li><a className="nav-link" href="#"><img src={logo_home} alt="" /></a></li>


                <li>

                    <ExternalLink className="nav-link" href={slug} onClick={toggleMenu2}>
                        <span>{takeTheTestTitle}</span>
                    </ExternalLink>
                </li>
              <li><Link className="nav-link" to={`/ingredients`} onClick={toggleMenu2}><span>{t(Ingredients)}</span></Link></li>

                <li>
                    {userData.isAuthenticated === true && token !== "" ? (
                        <Link className="nav-link" to={'/dashboard'} onClick={toggleMenu2}>
                            <span>{t(Profile)}</span>
                            </Link>
                            ) : (
                            <Link
                            className="nav-link"
                            to={`/contactus`}
                            onClick={toggleMenu2}>
                            <span>{t(Login)}</span>
                            </Link>
                    )}
                </li>
                <li><Link className="nav-link" to={`/contactus`} onClick={toggleMenu2}><span className="cart-quantity"></span> <span>{t(Cart)}</span></Link></li>

                <Language />
            </ul> */}
        </div>
      </header>
      {/* <section className='header-bottom-bar'>
        <h5>{freeShippingOrders}</h5>
      </section> */}
    </>
  );
};

export default Header;
