import React from "react";
import VeganImg from "../../../assets/vegan.webp";
import GlutenFreeImg from "../../../assets/gluten-free.webp";
import NoArtificialColoringImg from "../../../assets/no-additives.webp";
import SuitableForAthletesimg from "../../../assets/suitable-for-athletes-doping-free.webp";

const DATA = [
  VeganImg,
  GlutenFreeImg,
  NoArtificialColoringImg,
  SuitableForAthletesimg,
];
const GlutenFree = () => {
  return (
    <div className="md:t-hidden t-flex t-items-start t-justify-between t-p-4 t-bg-gray-200">
      {DATA.map((item, i) => (
        <div
          className="t-flex-none t-w-1/4 t-flex t-flex-col t-items-center t-justify-center t-gap-1"
          key={"unique-" + i}
        >
          <img src={item} alt="" height={40} width={40} />
        </div>
      ))}
    </div>
  );
};

export default GlutenFree;
