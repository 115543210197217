import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../features/langSlice";
import {
  useAddOmnisendEventMutation,
  useGetNutritionistsQuery,
  useUpdateQuizIngredientsMutation,
} from "../../features/quizApiSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getProductForWebAction,
  getSubsActions,
} from "../../app/api/useDataManage/subscriptionApis";
import { getCustomerProfileAction } from "../../app/api/useDataManage/userActions";
import { useTranslation } from "react-i18next";
import {
  AsSeenIn,
  IncludesIngredients,
  Nutrient,
  Quantity,
  SubscriptionOptions,
  SubscriptionDetailsLine,
} from "../../Constants";
import { logo } from "../../Data/Images/Home";
import ProductSuggestionWhyRiise from "./ProductSuggestionWhyRiise";
import Language from "../../Layout/Header/Language";
import SubscriptionCard from "../SubscriptionCard";
import {
  ICONS_DATA,
  calculateIngredientPrice,
} from "../../utils/productSuggestions";
import MobileSubscriptionCard from "../MobileSubscriptionCard";
import AddIngredientModal from "./components/AddIngredientModal";
import TableRow from "./components/TableRow";
import Reviews from "./components/Reviews";
import ExpertSection from "./components/ExpertSection";
import useProduct from "../../hooks/useProduct";
import {
  useCustomFormulaMutation,
  useGetCustomFormulaQuery,
  useExistingFormulaMutation,
} from "../../features/customFormulaApiSlice";
import { toast } from "react-toastify";
import ReviewsMobile from "./components/ReviewsMobile";
import CustomFormulaForm from "./components/CustomFormulaForm";
import Whatsapp from "../../assets/whatsapp.webp";
import InfoCard from "./components/InfoCard";
import PaymentMethods from "../../assets/payment-methods.webp";
import Shipping from "../../assets/shipping-DHL.webp";
import ReviewsImage from "../../assets/reviews.webp";
import Loader from "../elements/Loader";

const CustomFormula = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [formula_id, setFormulaId] = useState(searchParams.get("formula_id"));

  const {
    onInputChange,
    removeIngredientFromBin,
    addIngredient,
    removeIngredient,
    onRemoveQuantity,
    onAddQuantity,
    onSelectPlan,
    allIngredients,
    allIngredientCodes,
    allIngredientPrices,
    allIngredientPriceLoading,
    isProductPricingLoading,
    news,
    lang,
    data,
    setData,
    ingredients,
    setIngredients,
    quantitiesChanged,
    setQuantitiesChanged,
    error,
    setError,
    prices,
    setPrices,
    pricesData,
    setPricesData,
    totals,
    setTotals,
    continent,
    paymentLink,
    setPaymentLink,
    subscribtion,
    setSubscription,
    token,
    addIngredientsModal,
    setAddIngredientsModal,
    newSubs,
    setNewSubs,
    ONE_OFF_SUB,
  } = useProduct();

  const selectedLang = useSelector(selectCurrentLanguage);
  const { subs } = useSelector((state) => state.subs);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [discount_code, setDiscountCode] = useState("");
  const [note, setNote] = useState("");
  const [nutritionist, setNutritionist] = useState("");
  const [nutritionistName, setNutritionistName] = useState("");
  const [nutritionistData, setNutritionistData] = useState("");
  const [practitionerCode, setPractitionerCode] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [isExistingFormula, setIsExistingFormula] = useState(false);
  const buttonRef = useRef(null);
  const boxRef = useRef(null);
  const subsRef = useRef();
  const formRef = useRef();

  // All queries and mutations
  const [updateQuizIngredientsQuantity, { isLoading: updateQuizLoading }] =
    useUpdateQuizIngredientsMutation();
  const [customFormulaMutation, { isLoading: isCreateCustomFormulaLoading }] =
    useCustomFormulaMutation();
  const {
    data: customFormula,
    refetch,
    isLoading: isGetCustomFormulaLoading,
  } = useGetCustomFormulaQuery(formula_id);
  const { data: nutritionists, isLoading: getNutritionistsLoading } =
    useGetNutritionistsQuery();
  const [existingFormula, { isLoading: existingFormulaLoading }] =
    useExistingFormulaMutation();

  const loading =
    updateQuizLoading ||
    isCreateCustomFormulaLoading ||
    isGetCustomFormulaLoading ||
    getNutritionistsLoading ||
    existingFormulaLoading;

  // ALl USE EFFECTS HERE
  useEffect(() => {
    if (subs) {
      const tempSubs = subs.slice(1, subs.length);
      const sub = [ONE_OFF_SUB, ...tempSubs];
      setNewSubs(sub);
    }
  }, [subs, setNewSubs, ONE_OFF_SUB]);

  useEffect(() => {
    if (!formula_id) return;
    refetch();
  }, [formula_id]);

  useEffect(() => {
    if (
      !formula_id ||
      !customFormula?.customFormula ||
      !nutritionists?.nutritionists
    )
      return;
    const nutri = nutritionists?.nutritionists?.find(
      (item) => item.code === customFormula?.customFormula?.nutritionist
    );
    setNutritionistName(nutri?.name);
  }, [formula_id, customFormula, nutritionists?.nutritionists]);

  useEffect(() => {
    if (!practitionerCode || !nutritionists?.nutritionists) return;
    const nutri = nutritionists?.nutritionists?.find(
      (item) => item.code === practitionerCode
    );
    setNutritionistData(nutri);
  }, [practitionerCode, nutritionists?.nutritionists]);

  useEffect(() => {
    if (!formula_id) return;
    if (customFormula && customFormula?.customFormula) {
      const tempData = customFormula?.customFormula?.ingredients?.map(
        (item) => {
          const ingredient = allIngredients?.data?.find(
            (i) => i?.id === item?.ingredient_id
          );
          return {
            daily_dose: item?.daily_dose,
            description: ingredient?.description,
            ingredient: ingredient?.ingredient,
            ingredient_id: ingredient?.id,
            per_eu_nrv: item?.per_eu_nrv,
            id: item?.id,
          };
        }
      );
      setData(tempData);
      setIngredients(tempData);
    }
  }, [formula_id, customFormula, allIngredients, setData, setIngredients]);

  useEffect(() => {
    dispatch(getSubsActions(selectedLang ?? "en"));
    dispatch(getProductForWebAction());
    dispatch(getCustomerProfileAction(token));
  }, [dispatch, selectedLang, token]);

  // This will calculate the initial prices for the ingredients
  useEffect(() => {
    if (!ingredients || !allIngredientPrices?.data?.length || !formula_id)
      return;
    setPrices({});
    customFormula?.customFormula?.ingredients?.forEach((item) => {
      const ingredient = allIngredients?.data?.find(
        (i) => i?.id === item?.ingredient_id
      );
      if (parseFloat(item.daily_dose) > 0) {
        calculateIngredientPrice(
          { ...item, ingredient: ingredient?.ingredient },
          parseFloat(item.daily_dose),
          setPrices,
          setPaymentLink,
          setSubscription,
          allIngredientPrices
        );
        calculateIngredientPrice(
          { ...item, ingredient: ingredient?.ingredient },
          parseFloat(item.daily_dose),
          setPricesData,
          setPaymentLink,
          setSubscription,
          allIngredientPrices
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingredients, allIngredientPrices]);

  // THis will calculate the price for the product
  useEffect(() => {
    const totalSum = Object.values(prices).reduce(
      (sum, value) => sum + parseFloat(value),
      0
    );
    const shipping = totalSum >= 55 ? 0 : continent === "eu" ? 6 : 12;

    setTotals({
      oneMonth: totalSum + shipping,
      threeMonths: totalSum * 0.85, // 15% off of 1 month price, free shipping
      sixMonths: totalSum * 0.7, // 30% off of 1 month price, free shipping
    });
  }, [prices, continent, setTotals]);
  console.log("nutritionistData", nutritionistData);
  const onSaveFormula = (onlySave) => {
    if (!email || !name || !practitionerCode) {
      toast.error("Please enter name, email and practitioner code");
      return;
    }
    if (data.length === 0) {
      toast.error("Please add atleast one ingredient");
      return;
    }

    customFormulaMutation({
      name,
      email,
      data,
      nutritionist: practitionerCode,
      note,
      discount_code,
      partner_id: nutritionistData?.partner_id,
      onlySave,
    })
      .then((res) => {
        toast.success("Formula saved successfully");
        navigate("/custom-formula/success", { state: res });
      })
      .catch((err) => {
        console.log("ERROR => ", err);
      });
  };
  const [addOmnisendEventMutation] = useAddOmnisendEventMutation();
  const onSubmitData = () => {
    const filteredObjects = data.filter((obj1) => {
      const mainIngredients = ingredients?.find(
        (obj2) => obj2.ingredient_id === obj1.ingredient_id
      );
      return mainIngredients && obj1.daily_dose !== mainIngredients.daily_dose;
    });
    const addedItems = [];
    const removedItems = [];

    // Find newly added items
    data?.forEach((item) => {
      const ingFound = ingredients?.find(
        (ing) => ing?.ingredient_id === item?.ingredient_id
      );
      if (!ingFound) {
        addedItems.push(item);
      }
    });

    // Find removed items
    ingredients?.forEach((oldItem) => {
      if (
        !data?.some(
          (newItem) => newItem.ingredient_id === oldItem.ingredient_id
        )
      ) {
        removedItems.push(oldItem);
      }
    });

    const email = customFormula?.customFormula?.email;
    const userId = customFormula?.customFormula?.user_id;

    if (
      filteredObjects.length > 0 ||
      addedItems.length > 0 ||
      removedItems.length > 0
    ) {
      try {
        updateQuizIngredientsQuantity({
          updatedItems: filteredObjects,
          addedItems,
          removedItems,
          quizId: customFormula?.customFormula?.quiz_id,
        }).then((res) => {
          addOmnisendEventMutation({
            email,
            url: `${paymentLink}?prefilled_email=${email}&client_reference_id=${userId}`,
            subscribtion,
            price:
              subscribtion.duration === "6 months"
                ? Math.round(parseFloat(totals["sixMonths"]))
                : subscribtion.duration === "3 months"
                ? Math.round(parseFloat(totals["threeMonths"]))
                : Math.round(parseFloat(totals["oneMonth"])),
          })
            .then(() => {})
            .catch(() => {});
          window.location.href = `${paymentLink}?prefilled_email=${email}&client_reference_id=${userId}`;
        });
      } catch (err) {
        toast.error(err?.message || "Something went wrong");
        console.log("err => ", err);
      }
    } else {
      addOmnisendEventMutation({
        email,
        url: `${paymentLink}?prefilled_email=${email}&client_reference_id=${userId}`,
        subscribtion,
        price:
          subscribtion.duration === "6 months"
            ? Math.round(parseFloat(totals["sixMonths"]))
            : subscribtion.duration === "3 months"
            ? Math.round(parseFloat(totals["threeMonths"]))
            : Math.round(parseFloat(totals["oneMonth"])),
      })
        .then((res) => {})
        .catch((err) => {});
      window.location.href = `${paymentLink}?prefilled_email=${email}&client_reference_id=${userId}`;
    }
  };

  const loadExistingFormula = () => {
    if (!practitionerCode || !customerEmail) {
      toast.error("Please enter practitioner code and customer email");
      return;
    } else {
      existingFormula({
        nutritionist: practitionerCode,
        email: customerEmail,
      })
        .then((data) => {
          if (data?.data?.customFormula) {
            setFormulaId(data.data?.customFormula?.id);
            setName(data.data?.customFormula?.name);
            setEmail(data.data?.customFormula?.email);
            setNote(data.data?.customFormula?.note);
            setDiscountCode(data.data?.customFormula?.discount_code);
            setIsExistingFormula(true);
          } else if (data?.error?.data) {
            toast.error(data.error.data.message);
          }
        })
        .catch((error) => {
          console.log("Error => ", error);
        });
    }
  };

  return (
    <>
      <div className="t-sticky t-top-0 t-z-10 t-w-full t-bg-secondary t-flex t-items-center t-justify-center t-py-2">
        <div className="container t-flex t-items-center t-justify-center">
          <img src={logo} alt="" width={60} />
          <div className="t-ml-auto">
            <Language />
          </div>
        </div>
      </div>
      <div
        className="product-suggestion-section section-padding t-flex t-flex-col md:t-flex-row t-items-center t-gap-8"
        style={{ paddingTop: "10px" }}
      >
        <div className="t-relative container t-max-w-[1320px] t-mx-auto t-flex t-flex-col md:t-flex-row t-gap-8 md:t-gap-12">
          <div className="lg:t-w-[40%] t-flex t-flex-col t-gap-4 t-mt-9">
            {(!formula_id || isExistingFormula) && (
              <div className="t-flex t-flex-col t-gap-4">
                <div className="t-flex t-items-center t-gap-8">
                  <h2>Login</h2>
                </div>
                <div className="!t-w-full md:!t-w-1/2 t-flex t-flex-col t-items-start t-gap-0 position-relative">
                  <label className="t-text-gray-600 !t-ml-0">
                    Practitioner Code
                  </label>
                  <input
                    type="text"
                    name="practitionerCode"
                    id="practitionerCode"
                    placeholder="Enter your code"
                    className={`form-control t-h-[40px] !t-pl-3`}
                    value={practitionerCode}
                    onChange={(e) => setPractitionerCode(e.target.value)}
                    required
                    minLength={3}
                  />
                </div>
                <div className="t-flex t-items-center t-gap-8">
                  <h2>Load existing formula</h2>
                </div>
                <div className="!t-w-full md:!t-w-1/2 t-flex t-flex-col t-items-start t-gap-0 position-relative">
                  <label className="t-text-gray-600 !t-ml-0">
                    Customer Email
                  </label>
                  <input
                    type="text"
                    name="customerEmail"
                    id="customerEmail"
                    placeholder="Enter customer email"
                    className={`form-control t-h-[40px] !t-pl-3`}
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                    required
                    minLength={3}
                  />
                </div>
                <button
                  className="btn !t-py-1 !t-w-[150px] !t-mx-auto text-lowercase btn-custom-quaternary !t-ml-0 !t-rounded-md"
                  disabled={loading}
                  onClick={loadExistingFormula}
                >
                  Load Formula
                </button>
              </div>
            )}
            <div className="t-flex t-items-center t-gap-8">
              <h2>Create your own formula</h2>
            </div>

            <div className="t-flex t-flex-wrap t-gap-2">
              {ICONS_DATA.map((item, i) => (
                <img
                  src={item}
                  alt=""
                  className="t-h-8 t-w-8"
                  key={"icons-data-" + i}
                />
              ))}
            </div>
            <div className="t-flex t-items-center t-justify-between t-gap-4">
              <h5 className="t-text-lg md:t-text-2xl">
                {t(IncludesIngredients).replace("{0}", data.length)}
              </h5>

              {data?.length ? (
                <div className="t-flex i-items-center t-gap-4">
                  <button
                    className="t-group btn !t-w-fit !t-px-3 text-capitalize btn-custom-quaternary !t-rounded-md !t-py-1"
                    onClick={() => {
                      setQuantitiesChanged(false);
                      setData(ingredients);
                      setError({});
                      setPrices(pricesData);
                    }}
                    disabled={!quantitiesChanged}
                  >
                    Reset
                  </button>
                  <button
                    className="btn !t-w-fit text-capitalize btn-custom-quaternary !t-rounded-md !t-py-1 !t-px-3"
                    onClick={() => setAddIngredientsModal(true)}
                  >
                    Add
                  </button>
                </div>
              ) : null}
            </div>
            {customFormula?.customFormula && (
              <div className="t-flex t-flex-col t-gap-0">
                {nutritionistName && (
                  <h5 className="t-text-base md:t-text-lg t-text-gray-500">
                    Practitioner:{" "}
                    <span className="!t-font-bold t-text-black">
                      {nutritionistName}
                    </span>
                  </h5>
                )}
                {customFormula?.customFormula?.note && (
                  <h5 className="t-text-base md:t-text-lg t-text-gray-500">
                    Note:{" "}
                    <span className="!t-font-bold t-text-black">
                      {customFormula?.customFormula?.note}
                    </span>
                  </h5>
                )}
                {customFormula?.customFormula?.discount_code && (
                  <h5 className="t-text-base md:t-text-lg t-text-gray-500">
                    Discount code:{" "}
                    <span className="!t-font-bold t-text-black">
                      {customFormula?.customFormula?.discount_code}
                    </span>
                  </h5>
                )}
              </div>
            )}

            <div className="table-responsive -t-mt-2">
              {data?.length === 0 ? (
                <div className="t-flex t-flex-col t-items-center t-justify-center t-p-4">
                  <h4 className="t-text-center">
                    You don't have any ingredients, add ingredient now
                  </h4>
                  <button
                    className="btn !t-w-fit text-capitalize btn-custom-quaternary !t-rounded-md !t-py-1 !t-px-3"
                    onClick={() => setAddIngredientsModal(true)}
                  >
                    Add
                  </button>
                </div>
              ) : (
                <table className="table accordion" id="accordionExample">
                  <thead>
                    <tr>
                      <td className="t-text-[11px] md:t-text-sm t-w-[35%] md:t-w-[40%] t-font-bold">
                        {t(Nutrient)}
                      </td>
                      <td className="t-text-[11px] md:t-text-sm t-text-center t-font-bold">
                        {t(Quantity)}
                      </td>
                      <td className="t-text-[11px] md:t-text-sm !t-text-center t-font-bold">
                        %NRV
                      </td>
                      <td className="t-text-[11px] md:t-text-sm !t-text-center t-font-bold">
                        €
                      </td>
                      <td className="t-text-[11px] md:t-text-sm !t-text-center t-font-bold"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((item, i) => {
                        const ingredient = allIngredients?.data?.find(
                          (ing) =>
                            ing.ingredient === item?.ingredient ||
                            ing.ingredient_de === item?.ingredient
                        );
                        const ingredientData = allIngredientCodes?.data?.find(
                          (ing) => ing?.code === ingredient?.id
                        );
                        const euNrv = parseFloat(ingredientData?.eu_nrv);
                        return (
                          <TableRow
                            key={"ingredient-" + i}
                            item={item}
                            i={i}
                            onRemoveQuantity={onRemoveQuantity}
                            ingredientData={ingredientData}
                            onAddQuantity={onAddQuantity}
                            prices={prices}
                            removeIngredientFromBin={removeIngredientFromBin}
                            euNrv={euNrv}
                            onInputChange={onInputChange}
                            isCustomFormula={true}
                          />
                        );
                      })}
                  </tbody>
                </table>
              )}
            </div>
            <section className="section-padding !t-pt-0 !t-pb-0">
              <h1 className="t-text-[18px] t-text-center t-font-bold t-mb-0 t-text-tertiary">
                {t(AsSeenIn)}
              </h1>
              <div className="container t-flex t-flex-row t-items-center t-justify-center t-gap-2 md:t-gap-4 lg:t-gap-8">
                {news?.data?.map((item) => (
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}${item?.url}`}
                    alt=""
                    className="t-w-16 t-h-auto md:t-w-[15%]"
                    key={"news-img-" + item.url}
                  />
                ))}
              </div>
            </section>
            <ProductSuggestionWhyRiise />
            <div className="t-hidden md:t-flex t-flex-col t-gap-4 t-overflow-auto t-mt-4">
              <h2>What our customers say</h2>
              <Reviews />
            </div>
            <div className="t-hidden md:t-flex t-flex-col t-gap-4 t-overflow-auto t-mt-4">
              <h2>Formulated by experts</h2>
              <ExpertSection lang={lang} />
            </div>
          </div>
          <div
            ref={subsRef}
            className="t-flex t-flex-col t-gap-4 !md:t-sticky !md:t-top-0 !t-h-fit sticky-top-products !t-pb-[100px]"
          >
            <h2 className="mb-0">{t(SubscriptionOptions)}</h2>
            <p className="t-text-sm t-mt-0 t-mb-1">
              {t(SubscriptionDetailsLine)}
            </p>
            {(!formula_id || isExistingFormula) && (
              <div className="t-hidden md:t-flex !t-w-full">
                <CustomFormulaForm
                  name={name}
                  setName={setName}
                  email={email}
                  setEmail={setEmail}
                  onSaveFormula={onSaveFormula}
                  note={note}
                  setNote={setNote}
                  discount_code={discount_code}
                  setDiscountCode={setDiscountCode}
                  nutritionist={nutritionist}
                  setNutritionist={setNutritionist}
                  nutritionists={nutritionists?.nutritionists}
                  isCreateCustomFormulaLoading={loading}
                />
              </div>
            )}
            <div className="t-hidden sm:t-flex t-flex-wrap t-justify-center t-items-stretch t-gap-4 t-pb-32 md:t-pb-0">
              {newSubs?.slice(0, 3)?.map((item, key) => (
                <SubscriptionCard
                  item={item}
                  onSelectPlan={onSelectPlan}
                  subscribtion={subscribtion}
                  id={key}
                  totals={totals}
                  key={"subscription-" + key}
                  loading={isProductPricingLoading || allIngredientPriceLoading}
                  showSelectBtn={!!formula_id}
                />
              ))}
            </div>
            <MobileSubscriptionCard
              username="Custom Formula"
              subscribtion={subscribtion}
              subs={newSubs}
              onSelectPlan={onSelectPlan}
              totals={totals}
              paymentLink={paymentLink}
              onSubmitData={onSubmitData}
              error={error}
              boxRef={boxRef}
              showBtn={false}
            />
            <div className="t-flex md:t-hidden  t-items-start t-justify-center t-gap-8">
              <InfoCard
                image={PaymentMethods}
                desc="Secure payment"
                className="md:!t-w-1/2"
              />
              <InfoCard
                image={Shipping}
                desc="Shipped in 2-3 days"
                pClass="md:t-mt-[10px]"
              />
              <InfoCard
                image={ReviewsImage}
                desc="Verified"
                pClass="md:t-mt-[10px]"
              />
            </div>
            {(!formula_id || isExistingFormula) && (
              <div className="md:t-hidden" ref={formRef}>
                <CustomFormulaForm
                  name={name}
                  setName={setName}
                  email={email}
                  setEmail={setEmail}
                  onSaveFormula={onSaveFormula}
                  note={note}
                  setNote={setNote}
                  discount_code={discount_code}
                  setDiscountCode={setDiscountCode}
                  nutritionist={nutritionist}
                  setNutritionist={setNutritionist}
                  nutritionists={nutritionists?.nutritionists}
                  isCreateCustomFormulaLoading={loading}
                />
              </div>
            )}
            <div className="md:t-hidden t-flex t-flex-col t-gap-4 t-overflow-auto t-mt-4">
              <h2>What our customers say</h2>
              <ReviewsMobile />
            </div>
            <div className="md:t-hidden t-flex t-flex-col t-gap-4 t-overflow-auto t-mt-4">
              <h2>Formulated by experts</h2>
              <ExpertSection />
            </div>
            {!!formula_id && (
              <div className="t-hidden md:t-grid gap-3 mt-2">
                {paymentLink ? (
                  <button
                    className="btn !t-py-2 fs-5 !t-w-[300px] !t-mx-auto text-capitalize btn-custom-quaternary"
                    onClick={onSubmitData}
                    disabled={Object.values(error).some(
                      (value) => value === true
                    )}
                  >
                    <div>start now</div>
                  </button>
                ) : (
                  <div className="btn !t-py-2 fs-5 !t-w-[300px] !t-mx-auto text-capitalize btn-custom-quaternary-disable">
                    <div>start now</div>
                  </div>
                )}
              </div>
            )}
            <div className="t-hidden md:t-flex  t-items-start t-justify-center t-gap-8">
              <InfoCard
                image={PaymentMethods}
                desc="Secure payment"
                className="md:!t-w-1/2"
              />
              <InfoCard
                image={Shipping}
                desc="Shipped in 2-3 days"
                pClass="md:t-mt-[10px]"
              />
              <InfoCard
                image={ReviewsImage}
                desc="Verified"
                pClass="md:t-mt-[10px]"
              />
            </div>
          </div>
        </div>
        {!!formula_id && (
          <div
            className="t-flex t-flex-col t-items-center t-justify-center t-gap-2 md:t-hidden t-fixed t-bottom-0 t-bg-white t-z-40 t-w-full t-mx-auto t-py-4 "
            style={{ boxShadow: "10px 10px 20px 10px #efefef" }}
          >
            {paymentLink ? (
              <div
                ref={buttonRef}
                className="t-flex t-flex-col t-items-center t-justify-center t-gap-2"
              >
                <p className="t-text-xl !t-font-[600] !t-leading-normal !t-m-0">
                  Selected Plan:{" "}
                  {subscribtion?.title === "6 months"
                    ? Math.round(totals["sixMonths"])
                    : subscribtion?.title === "3 months"
                    ? Math.round(totals["threeMonths"])
                    : Math.round(totals["oneMonth"])}
                  €
                </p>
                <button
                  className="btn !t-py-2 fs-5 !t-w-[300px] !t-mx-auto text-capitalize btn-custom-quaternary"
                  // href={`${paymentLink}?prefilled_email=${state?.user?.user?.email}&client_reference_id=${state?.user?.user?.id}`}
                  onClick={onSubmitData}
                  disabled={Object.values(error).some(
                    (value) => value === true
                  )}
                >
                  <div>start now</div>
                </button>
              </div>
            ) : (
              <div ref={buttonRef}>
                <p className="t-text-xl !t-font-[600] !t-leading-normal !t-m-0 t-text-center">
                  Up to {Math.round(totals["oneMonth"])}€
                </p>
                <button
                  className="btn !t-w-fit text-capitalize btn-custom-quaternary !t-rounded-md"
                  onClick={() => {
                    const scrollTo = subsRef?.current.offsetTop;
                    window.scrollTo({
                      top: scrollTo,
                      behavior: "smooth",
                    });
                  }}
                >
                  Select a Plan
                </button>
              </div>
            )}
          </div>
        )}
        {!formula_id && (
          <div
            className="t-flex t-flex-col t-items-center t-justify-center t-gap-2 md:t-hidden t-fixed t-bottom-0 t-bg-white t-z-40 t-w-full t-mx-auto t-py-4 "
            style={{ boxShadow: "10px 10px 20px 10px #efefef" }}
          >
            <button
              className="btn !t-w-fit text-lowercase btn-custom-quaternary !t-rounded-md"
              onClick={() => {
                const scrollTo = formRef?.current.offsetTop;
                window.scrollTo({
                  top: scrollTo + 400,
                  behavior: "smooth",
                });
              }}
            >
              Save and send
            </button>
          </div>
        )}
        <a
          href="https://wa.me/33671498040"
          target="_blank"
          rel="noreferrer"
          className="t-fixed t-bottom-32 sm:t-bottom-5 t-right-5 t-z-50"
        >
          <img
            src={Whatsapp}
            alt=""
            className="t-h-10 t-w-10 md:t-h-15 md:t-w-15"
          />
        </a>
      </div>
      <AddIngredientModal
        open={addIngredientsModal}
        handleClose={() => setAddIngredientsModal(false)}
        data={data}
        removeIngredient={removeIngredient}
        addIngredient={addIngredient}
        allIngredients={allIngredients}
      />
      {loading && <Loader />}
    </>
  );
};

export default CustomFormula;
