import React from "react";
import { MixedIn, ProducedIn } from "../../Constants";
import { useTranslation } from "react-i18next";
import FranceFlag from "../../assets/images/france.webp";
import GermanyFlag from "../../assets/images/germany.webp";
const Made = () => {
  const { t } = useTranslation();
  return (
    <div className="t-flex !t-items-center t-gap-1 ">
      <p className="t-text-sm md:t-text-base">{t(ProducedIn)}</p>
      <img
        src={FranceFlag}
        alt=""
        className="t-w-4 t-h-3 -t-mt-4 sm:-t-mt-3"
        width="16"
        height="12"
      />
      <p className="t-text-sm md:t-text-base">{t(MixedIn)}</p>
      <img
        src={GermanyFlag}
        alt=""
        className="t-w-4 t-h-3 -t-mt-4 sm:-t-mt-3"
        width="16"
        height="12"
      />
    </div>
  );
};

export default Made;
