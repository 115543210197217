import React from "react";
import {
  BenefitCarrotCareDesc,
  BenefitCarrotCareHeading,
  BenefitLifeLabDesc,
  BenefitLifeLabHeading,
  Benefits,
} from "../../Constants";
import CarrotCareSvg from "../../assets/carrot-care.png";
import LifelabSvg from "../../assets/lifelab.svg";
import DashboardWrapper from "./components/DashboardWrapper";
import { useTranslation } from "react-i18next";

const BenefitsComponent = () => {
  const { t } = useTranslation();
  const data = [
    {
      heading: "Carrot Care",
      title: t(BenefitCarrotCareHeading),
      desc: t(BenefitCarrotCareDesc),

      img: CarrotCareSvg,
      link: "https://carrotcare.health/",
    },
    {
      heading: "Lifelab",
      title: t(BenefitLifeLabHeading),
      desc: t(BenefitLifeLabDesc),
      img: LifelabSvg,
      link: "https://www.lifelab.de/",
    },
  ];
  return (
    <DashboardWrapper title={t(Benefits)}>
      <div className="t-flex t-flex-col t-gap-12">
        <h4 className="t-font-bold">{t(Benefits)}</h4>
        <div className="t-flex t-flex-wrap t-gap-8">
          {data.map((item) => (
            <div
              className="t-flex t-flex-col t-items-center t-justify-center t-gap-4 sm:t-max-w-[300px]"
              key={"benefit-" + item.title}
            >
              <h3 className="t-m-0">{item.heading}</h3>
              <div className="t-flex t-items-center t-justify-center t-h-[15%]">
                <img src={item.img} alt="" className="" />
              </div>
              <h4 className="t-m-0">{item.title}</h4>
              <p className="t-m-0 t-text-center">{item.desc}</p>
              <a
                href={item.link}
                target="_blank"
                rel="noreferrer"
                className="!t-text-lg"
              >
                {item.link}
              </a>
            </div>
          ))}
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default BenefitsComponent;
