import React from "react";
import { useGetAssetsQuery } from "../../../features/quizApiSlice";

const News = () => {
  const { data: news } = useGetAssetsQuery("homepageNews");
  return (
    <section className="section-padding !t-pt-4 !t-pb-0 t-h-[102px]">
      <div className="container t-flex t-flex-row t-items-center t-justify-center t-gap-2 md:t-gap-4 lg:t-gap-8">
        {news?.data?.map((item) => (
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}${item?.url}`}
            alt=""
            className="t-w-16 t-h-auto md:t-w-[5%]"
            key={"news-img-" + item.url}
          />
        ))}
      </div>
    </section>
  );
};

export default News;
