import React from "react";
import BannerImg from "../../../assets/focusareas/energy-banner.webp";
const BannerMobile = ({ heading1, heading2 }) => {
  return (
    <div className="t-relative md:t-hidden">
      <img src={BannerImg} alt="" />
      <div className="t-w-full t-absolute t-top-4 t-z-10 t-flex t-flex-col t-items-center -t-gap-2 t-px-2">
        <h1 className="t-text-[28px] t-text-center t-m-0">{heading1}</h1>
        <h1
          className="t-text-[28px] t-font-extrabold t-text-center t-m-0"
          style={{ fontFamily: "Roboto" }}
        >
          {heading2}
        </h1>
      </div>
    </div>
  );
};

export default BannerMobile;
