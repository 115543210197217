import React from "react";
import { Link } from "react-router-dom";
import PartnerLogo from "../../assets/partner/PartnerLogo.webp";

const Header = ({ showButton }) => {
  return (
    <div className="t-sticky t-top-0 t-w-full t-flex t-items-center t-justify-between t-bg-secondary t-py-2 t-px-4 t-z-20">
      <Link to="/">
        <img src={PartnerLogo} alt="" className="t-w-1/4 lg:t-w-1/6" />
      </Link>
      {showButton && (
        <Link to="/custom-quiz?partner_id=carrotcare">
          <button className="!t-w-[100px] !t-bg-primary t-text-black !t-rounded-md t-px-2 t-py-1">
            Start now
          </button>
        </Link>
      )}
    </div>
  );
};

export default Header;
