import React from "react";
import Here1 from "../../../assets/here1.webp";
import Here2 from "../../../assets/here2.webp";
import Here3 from "../../../assets/here3.webp";
import { useTranslation } from "react-i18next";
import {
  HereDesc1,
  HereDesc2,
  HereDesc3,
  HereHeading1,
  HereHeading2,
  HereHeading3,
} from "../../../Constants";

const HereForYou = ({ isProductPage = false }) => {
  const { t } = useTranslation();

  const DATA = [
    {
      heading: t(HereHeading1),
      desc: t(HereDesc1),
      image: Here1,
      href: "https://wa.me/33671498040",
    },
    {
      heading: t(HereHeading2),
      desc: t(HereDesc2),
      image: Here2,
      href: "https://calendly.com/alessandro-shift-mgmt/riise-with-ale",
    },
    {
      heading: t(HereHeading3),
      desc: t(HereDesc3),
      image: Here3,
      href: "hi@weriise.co",
    },
  ];
  return (
    <div
      className={`t-flex t-items-center t-justify-center t-gap-4 md:t-gap-8 t-w-full `}
    >
      {DATA.map((item) => (
        <div
          className="t-flex t-flex-col t-items-center t-self-stretch t-gap-0 md:t-gap-4"
          key={"here-item-" + item.heading}
        >
          <h6 className="t-font-bold t-text-center t-h-[20%] !t-leading-5">
            {item.heading}
          </h6>
          <a
            href={
              item.href === "hi@weriise.co" ? "mailto:hi@weriise.co" : item.href
            }
            target="_blank"
            rel="noreferrer"
            className="t-h-[50%] t-flex t-items-center t-justify-center"
          >
            <img
              src={item.image}
              alt=""
              className={`${
                item.href === "hi@weriise.co" ? "t-w-1/3" : "t-w-1/3 md:t-w-1/2"
              }`}
            />
          </a>
          <p className="t-m-0 t-mt-auto t-text-gray-600 t-h-[30%] t-text-center">
            {item.desc}
          </p>
        </div>
      ))}
    </div>
  );
};

export default HereForYou;
