import React from "react";
import { useTranslation } from "react-i18next";
import {
  UniqueHeading,
  UniqueSlide1Desc,
  UniqueSlide1Heading,
  UniqueSlide2Desc,
  UniqueSlide2Heading,
  UniqueSlide3Desc,
  UniqueSlide3Heading,
} from "../../../Constants";
import FingerPrint from "../../../assets/images/home/finger-print.webp";
import Granules from "../../../assets/images/home/granules.webp";
import Arrows from "../../../assets/images/home/arrows.webp";
import UniqueImage from "../../../assets/images/home/UniqueFormula.webp";
import UniqueImageDe from "../../../assets/images/home/UniqueFormulaDe.png";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../features/langSlice";
const UniqueWeb = () => {
  const { t } = useTranslation();
  const selectedLang = useSelector(selectCurrentLanguage);

  const SLIDER_DATA = [
    {
      img: FingerPrint,
      title: t(UniqueSlide1Heading),
      description: t(UniqueSlide1Desc),
    },
    {
      img: Granules,
      title: t(UniqueSlide2Heading),
      description: t(UniqueSlide2Desc),
    },
    {
      img: Arrows,
      title: t(UniqueSlide3Heading),
      description: t(UniqueSlide3Desc),
    },
  ];
  return (
    <div className=" t-bg-[#29a999]">
      <div className="t-max-w-[1330px] t-flex t-flex-col t-items-center t-justify-center !t-mx-auto t-pt-8">
        <h2>{t(UniqueHeading)}</h2>
        <div className="t-flex t-items-center t-justify-between t-gap-4 !t-mx-auto t-px-8">
          <div className="t-flex t-flex-col t-gap-4 py-4">
            {SLIDER_DATA.map((item) => (
              <div
                className="t-mx-auto t-max-w-[400px]"
                key={"unique-slider-" + item.title}
              >
                <div className="t-w-full t-flex t-flex-col t-gap-1 t-bg-gray-100 t-rounded-lg t-p-2 t-overflow-hidden">
                  <div className="t-flex t-items-center t-overflow-hidden t-w-full t-gap-2">
                    <img
                      src={item.img}
                      alt=""
                      className="t-flex-none !t-h-[10%] !t-w-[10%] sm:!t-h-[7%] sm:!t-w-[7%] t-object-cover"
                    />
                    <h5
                      className="t-m-0 t-text-secondary t-text-left !t-font-bold"
                      style={{
                        fontFamily: "Roboto",
                        boxSizing: "border-box",
                      }}
                    >
                      {item.title}
                    </h5>
                  </div>
                  <p className="t-m-0 t-text-left t-text-secondary">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <img
            src={selectedLang === "de" ? UniqueImageDe : UniqueImage}
            alt=""
            className="t-w-[50%] mt-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default UniqueWeb;
