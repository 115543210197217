import React from "react";
import { useTranslation } from "react-i18next";
import {
  GlutenFree,
  NoArtificialCloning,
  SuitableForAthletes,
  Vegan,
} from "../../../Constants";
import VeganImg from "../../../assets/vegan.webp";
import GlutenFreeImg from "../../../assets/gluten-free.webp";
import NoArtificialColoringImg from "../../../assets/no-additives.webp";
import SuitableForAthletesimg from "../../../assets/suitable-for-athletes-doping-free.webp";
const GlutenFreeWeb = () => {
  const { t } = useTranslation();
  const DATA = [
    { title: t(Vegan), img: VeganImg },
    { title: t(GlutenFree), img: GlutenFreeImg },
    { title: t(NoArtificialCloning), img: NoArtificialColoringImg },
    { title: t(SuitableForAthletes), img: SuitableForAthletesimg },
  ];
  return (
    <div className="t-flex t-items-start t-justify-between t-p-2 t-bg-gray-200">
      <div className="t-flex t-items-start t-justify-between t-p-4 sm:t-gap-4 t-max-w-[800px] t-mx-auto">
        {DATA.map((item) => (
          <div
            className="t-flex-none t-w-1/4 t-flex t-flex-col t-items-center t-justify-center t-gap-1"
            key={"unique-" + item.title}
          >
            <img src={item.img} alt="" height={40} width={40} />
            <p className="t-m-0 t-text-center t-text-secondary t-leading-4">
              {item.title}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GlutenFreeWeb;
