import React from "react";

const Description = ({ desc1, desc2 }) => {
  return (
    <div className="md:t-hidden t-flex t-flex-col t-gap-4 t-p-4">
      <p className="t-m-0 t-leading-4">{desc1}</p>
      <p className="t-m-0 t-leading-4">{desc2}</p>
    </div>
  );
};

export default Description;
