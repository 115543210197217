export const Home = "Home";
export const Products = "Products";
export const About = "About";
export const TermsConditions = "TermsConditions";
export const Policy = "Policy";
export const FAQs = "FAQs";
export const Blogs = "Blogs";
export const OurBlogs = "OurBlogs";
export const OurIngredients = "OurIngredients";
export const ContactUs_ = "ContactUs_";
export const Partner = "Partner";
export const SubscribeNow = "SubscribeNow";
export const RecentPost = "RecentPost";
export const UsefulLinks = "UsefulLinks";
export const StayConnected = "StayConnected";
export const SubscribeFirstPurchase = "SubscribeFirstPurchase";
export const HomeTitle1 = "HomeTitle1";
export const HomeTitle2 = "HomeTitle2";
export const HomeTitle3 = "HomeTitle3";

export const MixedIn = "MixedIn";
export const TakeTheTestButton = "TakeTheTestButton";
export const ProducedIn = "ProducedIn";
export const AsSeenIn = "AsSeenIn";
export const WhatAreYourWellnessGoals = "WhatAreYourWellnessGoals";
export const Vegan = "Vegan";
export const SuitableForAthletes = "SuitableForAthletes";
export const GlutenFree = "GlutenFree";
export const ColorantFree = "ColorantFree";
export const ItMattersToUs = "ItMattersToUs";

export const FreeInclude1 = "FreeInclude1";
export const FreeInclude2 = "FreeInclude2";
export const FreeInclude3 = "FreeInclude3";
export const Payments = "Payments";
export const FollowUs = "FollowUs";
export const TakeTheTest = "TakeTheTest";
export const TakeATest = "TakeATest";
export const DiscountCodes = "DiscountCodes";
export const Login = "Login";
export const LogoutTitle = "Logout";
export const LoggedOut = "LoggedOut";
export const EnterEmailHere = "EnterEmailHere";
export const Ingredients = "Ingredients";
export const Cart = "Cart";

export const AnyOtherQuestion = "AnyOtherQuestion";

export const EmailAddress = "EmailAddress";
export const ContactDetails = "ContactDetails";
export const Address = "Address";

export const SendInquiry = "SendInquiry";
export const FirstName = "FirstName";
export const LastName = "LastName";
export const Phone = "Phone";
export const Email = "Email";
export const Message = "Message";

export const Previous = "Previous";
export const YourFocusAreas = "YourFocusAreas";
export const MyFocusArea = "MyFocusArea";
export const YourFocusAreasDesc = "YourFocusAreasDesc";
export const PersonalizedFormula = "PersonalizedFormula";
export const PersonalizedFormulaDesc = "PersonalizedFormulaDesc";
export const IncludesIngredients = "IncludesIngredients";
export const Nutrient = "Nutrient";
export const Quantity = "Quantity";
export const WhyRiiseTitle = "WhyRiise";
export const SubscriptionOptions = "SubscriptionOptions";
export const SubscriptionDetailsLine = "SubscriptionDetailsLine";
export const ChooseYourPlan = "ChooseYourPlan";
export const ContinueMyCart = "ContinueMyCart";
export const Subscription = "Subscription";

export const CheckOut_ = "CheckOut_";
export const CheckOut__ = "CheckOut__";
export const YourSupplements = "YourSupplements";
export const CapsulePerDay = "CapsulePerDay";
export const YourOrder = "YourOrder";
export const SubTotal = "SubTotal";
export const AfterDiscount = "after discount";
export const Shipping = "Shipping";
export const Total = "Total";
export const Submit = "Submit";
export const Cancel = "Cancel";

export const RecipientDetails_ = "RecipientDetails";
export const CompanyName = "CompanyName";
export const ShippingDetails = "ShippingDetails";
export const CountryRegion = "CountryRegion";
export const StreetAddress = "StreetAddress";
export const Apartment = "Apartment";
export const State = "State";
export const Zipcode = "Zipcode";

export const Accepted = "Accepted";

export const Box1mLine1 = "Box1mLine1";
export const Box1mLine2 = "Box1mLine2";
export const Box1mLine3 = "Box1mLine3";
export const Box1mLine4 = "Box1mLine4";
export const Box3mLine1 = "Box3mLine1";
export const Box3mLine2 = "Box3mLine2";
export const Box3mLine3 = "Box3mLine3";
export const Box3mLine4 = "Box3mLine4";
export const Box6mLine1 = "Box6mLine1";
export const Box6mLine2 = "Box6mLine2";
export const Box6mLine3 = "Box6mLine3";
export const Box6mLine4 = "Box6mLine4";

export const UserDashboard = "UserDashboard";
export const DashboardTitle = "Dashboard";

export const ReferralCode = "ReferralCode";
export const Profile = "Profile";
export const MyOrders = "MyOrders";
export const ChangePassword = "ChangePassword";
export const InquiryTitle = "Inquiry";
export const CheckIn = "CheckIn";
export const TotalCheckIns = "TotalCheckIns";
export const FeelingText = "FeelingText";
export const AskSpecialist = "AskSpecialist";
export const Hello = "Hello";
export const WelcomeMessage = "WelcomeMessage";
export const Hi = "Hi";

export const Referrals = "Referrals";
export const RetakeTest = "RetakeTest";

export const ManageSubscription = "ManageSubscription";
export const CustomerService = "CustomerService";
export const Benefits = "Benefits";

export const FreeShippingWithSubscription = "FreeShippingWithSubscription";
export const Rating = "Rating";
export const ResetButton = "ResetButton";
export const AddButton = "AddButton";
export const IngredientsOverlay = "IngredientsOverlay";
export const Ingredients1 = "Ingredients1";
export const ChooseYourPlan1 = "ChooseYourPlan1";
export const TryOnce = "TryOnce";
export const MonthlyPlan = "MonthlyPlan";
export const ThreeMonthPlan = "ThreeMonthPlan";
export const Save15Percent = "Save15Percent";
export const Save30Percent = "Save30Percent";
export const SelectAPlanMobile = "SelectAPlanMobile";
export const StartNowButton = "StartNowButton";
export const SecurePayment = "SecurePayment";
export const ShippedIn2To3Days = "ShippedIn2To3Days";
export const Verified = "Verified";
export const CustomerReviews = "CustomerReviews";
export const Reviews = "Reviews";
export const FormulatedByExperts = "FormulatedByExperts";
export const FormulaHeading = "FormulaHeading";
export const Available = "Available";
export const AvailableText = "AvailableText";
export const PerDay = "PerDay";
export const Month = "Month";
export const Selected = "Selected";
export const Select = "Select";
export const MostPopular = "MostPopular";
export const BestValue = "BestValue";
export const HereMainHeading = "HereMainHeading";
export const HereHeading1 = "HereHeading1";
export const HereHeading2 = "HereHeading2";
export const HereHeading3 = "HereHeading3";
export const HereDesc1 = "HereDesc1";
export const HereDesc2 = "HereDesc2";
export const HereDesc3 = "HereDesc3";
export const PersonaliseMine = "PersonaliseMine";
export const Personalised = "Personalised";
export const Neil = "Neil";
export const Chris = "Chris";
export const Adina = "Adina";
export const TryOnceBox1 = "TryOnceBox1";
export const TryOnceBox2 = "TryOnceBox2";
export const FreeShipping = "FreeShipping";
export const FreeShippingInEu = "FreeShippingInEu";
export const BlackFriday = "BlackFriday";
export const GetOff = "GetOff";
export const ProductInfo1 = "ProductInfo1";
export const ProductInfo2 = "ProductInfo2";
export const ProductInfo3 = "ProductInfo3";
export const ProductInfo4 = "ProductInfo4";
export const ProductInfo5 = "ProductInfo5";
export const FreeShippingBanner = "FreeShippingBanner";
export const Off25 = "Off25";
export const BenefitCarrotCareHeading = "BenefitCarrotCareHeading";
export const BenefitCarrotCareDesc = "BenefitCarrotCareDesc";
export const BenefitLifeLabHeading = "BenefitLifeLabHeading";
export const BenefitLifeLabDesc = "BenefitLifeLabDesc";
export const Energy = "Energy";
export const Focus = "Focus";
export const SportPerformance = "SportPerformance";
export const Mood = "Mood";
export const Sleep = "Sleep";
export const Stress = "Stress";
export const Digestion = "Digestion";
export const ImmuneSystem = "ImmuneSystem";
export const Skin = "Skin";
export const Hair = "Hair";
export const Nails = "Nails";
export const Heart = "Heart";
export const MusclePain = "MusclePain";
export const Eyes = "Eyes";
export const BonesAndJoints = "BonesAndJoints";
export const You = "You";
export const Your = "Your";
export const BannerHeading1 = "BannerHeading1";
export const BannerHeading2 = "BannerHeading2";
export const BannerText1 = "BannerText1";
export const BannerText2 = "BannerText2";
export const UniqueHeading = "UniqueHeading";
export const NoArtificialCloning = "NoArtificialCloning";
export const UniqueSlide1Heading = "UniqueSlide1Heading";
export const UniqueSlide1Desc = "UniqueSlide1Desc";
export const UniqueSlide2Heading = "UniqueSlide2Heading";
export const UniqueSlide2Desc = "UniqueSlide2Desc";
export const UniqueSlide3Heading = "UniqueSlide3Heading";
export const UniqueSlide3Desc = "UniqueSlide3Desc";
export const HighStandardHeading = "HighStandardHeading";
export const HighStandardDesc1 = "HighStandardDesc1";
export const HighStandardDesc2 = "HighStandardDesc2";
export const HighStandardDesc3 = "HighStandardDesc3";
export const EachActive = "EachActive";
export const MillionCombinations1 = "MillionCombinations1";
export const MillionCombinations2 = "MillionCombinations2";
export const MillionCombinations3 = "MillionCombinations3";
export const RecommendationButton = "RecommendationButton";
export const MakeChoiceHeading = "MakeChoiceHeading";
export const MakeChoiceFeature = "MakeChoiceFeature";
export const TableRow1 = "TableRow1";
export const TableRow2 = "TableRow2";
export const TableRow3 = "TableRow3";
export const TableRow4 = "TableRow4";
export const TableRow5 = "TableRow5";
export const TableRow6 = "TableRow6";
export const TableRow7 = "TableRow7";
export const PartnersHeading = "PartnersHeading";
export const EnergyBanner1 = "EnergyBanner1";
export const EnergyBanner2 = "EnergyBanner2";
export const EnergyDesc1 = "EnergyDesc1";
export const EnergyDesc2 = "EnergyDesc2";
export const EnergyDesc3 = "EnergyDesc3";
export const EnergyDesc4 = "EnergyDesc4";
export const QuizButton = "QuizButton";
export const SupportEnergy = "SupportEnergy";
export const SupportEnergyDesc1 = "SupportEnergyDesc1";
export const SupportEnergyDesc2 = "SupportEnergyDesc2";
export const NutirentsBodyNeed = "NutirentsBodyNeed";
export const Magnesium = "Magnesium";
export const MagnesiumDesc = "MagnesiumDesc";
export const MagnesiumDetail1 = "MagnesiumDetail1";
export const MagnesiumDetail2 = "MagnesiumDetail2";
export const VitaminB12 = "VitaminB12";
export const VitaminB12Desc = "VitaminB12Desc";
export const VitaminB12Detail1 = "VitaminB12Detail1";
export const VitaminB12Detail2 = "VitaminB12Detail2";
export const Iron = "Iron";
export const IronDesc = "IronDesc";
export const IronDetail1 = "IronDetail1";
export const IronDetail2 = "IronDetail2";
export const VitaminD = "VitaminD";
export const VitaminDDesc = "VitaminDDesc";
export const VitaminDDetail1 = "VitaminDDetail1";
export const VitaminDDetail2 = "VitaminDDetail2";
export const Faq = "Faq";
export const FaqMore = "FaqMore";
export const FaqPage = "FaqPage";
export const FocusHeader = "FocusHeader";
export const FocusHeader1 = "FocusHeader1";
export const FocusIntro = "FocusIntro";
export const FocusFrustration = "FocusFrustration";
export const FocusSupport = "FocusSupport";
export const FocusVitamins = "FocusVitamins";
export const FocusVitaminB1 = "FocusVitaminB1";
export const FocusVitaminB6 = "FocusVitaminB6";
export const FocusBiotin = "FocusBiotin";
export const FocusFolate = "FocusFolate";
export const FocusVitaminB1Second = "FocusVitaminB1Second";
export const FocusVitaminB6Second = "FocusVitaminB6Second";
export const FocusBiotinSecond = "FocusBiotinSecond";
export const FocusFolateSecond = "FocusFolateSecond";
export const SportsPerformanceHeader = "SportsPerformanceHeader";
export const SportsPerformanceHeader1 = "SportsPerformanceHeader1";
export const SportsPerformanceIntro = "SportsPerformanceIntro";
export const SportsPerformanceSupport = "SportsPerformanceSupport";
export const SportsPerformanceVitamins = "SportsPerformanceVitamins";
export const SportsPerformanceMagnesium = "SportsPerformanceMagnesium";
export const SportsPerformanceIron = "SportsPerformanceIron";
export const SportsPerformanceB_Vitamins = "SportsPerformanceB_Vitamins";
export const SportsPerformanceZinc = "SportsPerformanceZinc";
export const SportsPerformanceMagnesiumSecond =
  "SportsPerformanceMagnesiumSecond";
export const SportsPerformanceIronSecond = "SportsPerformanceIronSecond";
export const SportsPerformanceB_VitaminsSecond =
  "SportsPerformanceB_VitaminsSecond";
export const SportsPerformanceZincSecond = "SportsPerformanceZincSecond";
export const SleepHeader = "SleepHeader";
export const SleepHeader1 = "SleepHeader1";
export const SleepDescription = "SleepDescription";
export const SleepNutrientsHeader = "SleepNutrientsHeader";
export const SleepMagnesium = "SleepMagnesium";
export const SleepMagnesiumSecond = "SleepMagnesiumSecond";
export const SleepVitaminB9 = "SleepVitaminB9";
export const SleepVitaminB6 = "SleepVitaminB6";
export const SleepZinc = "SleepZinc";
export const SleepVitaminB9Second = "SleepVitaminB9Second";
export const SleepVitaminB6Second = "SleepVitaminB6Second";
export const SleepZincSecond = "SleepZincSecond";
export const VitaminB6 = "VitaminB6";
export const VitaminB6Desc = "VitaminB6Desc";
export const VitaminB9 = "VitaminB9";
export const VitaminB9Desc = "VitaminB9Desc";
export const VitaminB1 = "VitaminB1";
export const VitaminB1Desc = "VitaminB1Desc";
export const Biotin = "Biotin";
export const BiotinDesc = "BiotinDesc";
export const Folate = "Folate";
export const FolateDesc = "FolateDesc";
export const Zinc = "Zinc";
export const ZincDesc = "ZincDesc";
export const BVitamins = "BVitamins";
export const BVitaminsDesc = "BVitaminsDesc";
export const Ashwagandha = "Ashwagandha";
export const AshwagandhaDesc = "AshwagandhaDesc";
export const VitaminC = "VitaminC";
export const VitaminCDesc = "VitaminCDesc";
export const Selenium = "Selenium";
export const SeleniumDesc = "SeleniumDesc";

export const StressHeader = "StressHeader";
export const StressHeader1 = "StressHeader1";
export const StressDescription = "StressDescription";
export const StressNutrientsHeader = "StressNutrientsHeader";
export const StressNutrientsHeader1 = "StressNutrientsHeader1";
export const StressMagnesium = "StressMagnesium";
export const StressMagnesiumSecond = "StressMagnesiumSecond";
export const StressBVitamins = "StressBVitamins";
export const StressBVitaminsSecond = "StressBVitaminsSecond";
export const StressAshwagandha = "StressAshwagandha";
export const StressAshwagandhaSecond = "StressAshwagandhaSecond";
export const StressVitaminC = "StressVitaminC";
export const StressVitaminCSecond = "StressVitaminCSecond";

export const MoodHeader = "MoodHeader";
export const MoodHeader1 = "MoodHeader1";
export const MoodDescription = "MoodDescription";
export const MoodDescription1 = "MoodDescription1";
export const MoodNutrientsHeader = "MoodNutrientsHeader";
export const MoodNutrientsHeader1 = "MoodNutrientsHeader1";
export const MoodMagnesium = "MoodMagnesium";
export const MoodMagnesiumSecond = "MoodMagnesiumSecond";
export const MoodVitaminB9 = "MoodVitaminB9";
export const MoodVitaminB9Second = "MoodVitaminB9Second";
export const MoodVitaminD = "MoodVitaminD";
export const MoodVitaminDSecond = "MoodVitaminDSecond";
export const MoodVitaminB12 = "MoodVitaminB12";
export const MoodVitaminB12Second = "MoodVitaminB12Second";

export const ImmuneSystemHeader = "ImmuneSystemHeader";
export const ImmuneSystemHeader1 = "ImmuneSystemHeader1";
export const ImmuneSystemDescription = "ImmuneSystemDescription";
export const ImmuneSystemDescription1 = "ImmuneSystemDescription1";
export const ImmuneSystemNutrientsHeader = "ImmuneSystemNutrientsHeader";
export const ImmuneSystemNutrientsHeader1 = "ImmuneSystemNutrientsHeader1";
export const ImmuneSystemVitaminD = "ImmuneSystemVitaminD";
export const ImmuneSystemVitaminDSecond = "ImmuneSystemVitaminDSecond";
export const ImmuneSystemVitaminC = "ImmuneSystemVitaminC";
export const ImmuneSystemVitaminCSecond = "ImmuneSystemVitaminCSecond";
export const ImmuneSystemZinc = "ImmuneSystemZinc";
export const ImmuneSystemZincSecond = "ImmuneSystemZincSecond";
export const ImmuneSystemSelenium = "ImmuneSystemSelenium";
export const ImmuneSystemSeleniumSecond = "ImmuneSystemSeleniumSecond";

export const SupportFocus = "SupportFocus";
export const SupportSportPerformance = "SupportSportPerformance";
export const SupportSleep = "SupportSleep";
export const SupportStress = "SupportStress";
export const SupportMood = "SupportMood";
export const SupportImmuneSystem = "SupportImmuneSystem";
export const CountdownText = "CountdownText";
