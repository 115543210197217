import React from "react";

const Nutrients = ({ heading, desc1, desc2, data }) => {
  return (
    <div className="t-flex t-flex-col t-gap-4 t-p-4 t-py-16">
      <h2 className="t-m-0 t-text-center">{heading}</h2>
      <div className="t-flex t-flex-col t-items-center t-gap-4 t-p-4">
        <p className="t-m-0 t-text-base t-leading-4 t-w-[50%]  t-text-center">
          {desc1}
        </p>
        <p className="t-m-0 t-text-base t-leading-4 t-w-[50%] t-text-center">
          {desc2}
        </p>
      </div>
      <div className="t-flex t-flex-wrap t-justify-center t-items-stretch t-gap-4">
        {data.map((item) => (
          <div className="t-relative t-flex t-flex-col t-items-start t-justify-start t-gap-2 t-p-4 t-max-w-[300px] t-bg-gray-200 t-rounded-lg">
            <div className="t-flex t-items-center t-gap-2">
              <img src={item.img} alt="" className="t-h-10 t-w-10" />
              <p
                className="t-m-0 t-text-base t-text-secondary t-font-[900]"
                style={{ fontFamily: "Roboto" }}
              >
                {item.title}
              </p>
            </div>
            <p className="t-m-0 t-text-secondary t-leading-4 t-text-sm">
              {item.details1}
            </p>
            <p className="t-m-0 t-text-secondary t-leading-4 t-text-sm ">
              {item.details2}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Nutrients;
