import React from "react";
import { useTranslation } from "react-i18next";
import {
  FocusHeader,
  FocusHeader1,
  FocusIntro,
  FocusFrustration,
  NutirentsBodyNeed,
  FocusSupport,
  FocusVitamins,
  FormulatedByExperts,
  VitaminB1,
  VitaminB1Desc,
  FocusVitaminB1,
  FocusVitaminB1Second,
  FocusVitaminB6,
  FocusVitaminB6Second,
  Biotin,
  BiotinDesc,
  Folate,
  FolateDesc,
  FocusFolate,
  FocusFolateSecond,
  FocusBiotin,
  FocusBiotinSecond,
  VitaminB6,
  VitaminB6Desc,
  SupportFocus,
} from "../../Constants";
import ExpertSection from "../Customer/components/ExpertSection";
import MillionCombinations from "../Home/MillionCombinations";
import HighStandards from "../Home/HighStandards";
import ReviewsOverlay from "../Reviews";
import Unique from "../Home/Unique";
import VitaminB1Img from "../../assets/focusareas/vitamin-B1-microgranules.webp";
import VitaminB6Img from "../../assets/focusareas/vitamin-B6-microgranules.webp";
import FolateImg from "../../assets/focusareas/folate.png";
import BiotinImg from "../../assets/focusareas/biotin.png";
import BannerMobile from "./components/BannerMobile";
import Description from "./components/Description";
import NutrientsMobile from "./components/NutrientsMobile";
import News from "./components/News";
import GlutenFree from "./components/GlutenFree";
import Banner from "./components/Banner";
import Nutrients from "./components/Nutrients";
import UniqueWeb from "./components/UniqueWeb";
import GlutenFreeWeb from "./components/GlutenFreeWeb";

const Focus = () => {
  const { t } = useTranslation();

  const INGREDIENTS_DATA = [
    {
      title: t(VitaminB1),
      desc: t(VitaminB1Desc),
      img: VitaminB1Img,
      details1: t(FocusVitaminB1),
      details2: t(FocusVitaminB1Second),
    },
    {
      title: t(VitaminB6),
      desc: t(VitaminB6Desc),
      img: VitaminB6Img,
      details1: t(FocusVitaminB6),
      details2: t(FocusVitaminB6Second),
    },
    {
      title: t(Biotin),
      desc: t(BiotinDesc),
      img: BiotinImg,
      details1: t(FocusBiotin),
      details2: t(FocusBiotinSecond),
    },
    {
      title: t(Folate),
      desc: t(FolateDesc),
      img: FolateImg,
      details1: t(FocusFolate),
      details2: t(FocusFolateSecond),
    },
  ];

  return (
    <div>
      <BannerMobile heading1={t(FocusHeader)} heading2={t(FocusHeader1)} />
      <div className="t-hidden md:t-block">
        <Banner
          heading1={t(FocusHeader)}
          heading2={t(FocusHeader1)}
          desc1={t(FocusIntro)}
          desc2={t(FocusFrustration)}
        />
        <Nutrients
          heading={t(NutirentsBodyNeed)}
          desc1={t(FocusSupport)}
          desc2={t(FocusVitamins)}
          data={INGREDIENTS_DATA}
        />

        <UniqueWeb />
        <GlutenFreeWeb />
      </div>
      <Description desc1={t(FocusIntro)} desc2={t(FocusFrustration)} />
      <NutrientsMobile
        heading={t(NutirentsBodyNeed)}
        desc1={t(FocusSupport)}
        desc2={t(FocusVitamins)}
        data={INGREDIENTS_DATA}
      />
      <div className="md:t-hidden">
        <Unique isFocusArea heading={t(SupportFocus)} />
      </div>
      <div>
        <MillionCombinations slug="/quiz" isFocusArea />
      </div>
      <section className="md:t-hidden t-flex t-items-center t-justify-center t-py-2 t-bg-gray-100 t-h-10">
        <ReviewsOverlay totalReviews={55} averageReviews={4.92} />
      </section>
      <div className="t-flex t-flex-col t-gap-4 t-p-4 t-max-w-[800px] !t-mx-auto">
        <h1 className="t-text-[24px] t-text-center">
          {t(FormulatedByExperts)}
        </h1>
        <ExpertSection />
      </div>
      <div>
        <HighStandards />
      </div>
      <GlutenFree />
      <News />
    </div>
  );
};

export default Focus;
