import React from "react";
import ProductImage1 from "../assets/product/product1.webp";
import ProductImage3 from "../assets/product/product3.webp";
import OneMonth from "../assets/product/1m-new.webp";
import ThreeMonth from "../assets/product/3m-new.webp";
import SixMonth from "../assets/product/6m-new.webp";
import Ingredients from "../assets/product/tryonce.webp";
import { Icon } from "./elements/icon";
import { useTranslation } from "react-i18next";
import {
  Box1mLine1,
  Box1mLine2,
  Box1mLine3,
  Box1mLine4,
  Box3mLine1,
  Box6mLine1,
  Box6mLine2,
  Box6mLine3,
  Box6mLine4,
  ChooseYourPlan,
  TryOnce,
  MonthlyPlan,
  ThreeMonthPlan,
  Save15Percent,
  Save30Percent,
  StartNowButton,
  PerDay,
  Month,
  TryOnceBox1,
  TryOnceBox2,
  FreeShipping,
  FreeShippingInEu,
} from "../Constants";

const MobileSubscriptionCard = ({
  username,
  subscribtion,
  subs,
  onSelectPlan,
  totals,
  paymentLink,
  onSubmitData,
  error,
  showBtn = true,
  boxRef,
  onSelectOneOff,
}) => {
  const { t } = useTranslation();
  return (
    <div className="sm:t-hidden t-relative !t-w-full t-bg-white !t-max-w-[500px] t-flex t-flex-col t-px-2 md:t-px-4 t-py-4 md:t-py-6 t-border t-border-gry-200 t-rounded-lg t-gap-2">
      <div className="t-flex t-flex-row t-gap-4">
        <div className="t-flex t-flex-col t-items-start t-justify-between t-gap-4">
          <p className="t-m-0 t-font-[700] t-text-2xl">{username}'s mix</p>
          <img
            src={
              subscribtion?.duration === "6 months"
                ? SixMonth
                : subscribtion?.duration === "3 months"
                ? ThreeMonth
                : OneMonth
            }
            alt=""
            className={`${
              subscribtion?.duration === "30 days" && "t-h-[60px]"
            }`}
          />
          <p className="t-m-0 t-font-bold t-text-lg">{t(ChooseYourPlan)}</p>
        </div>
        <img
          src={
            subscribtion?.duration === "6 months"
              ? ProductImage3
              : subscribtion?.duration === "3 months"
              ? ProductImage1
              : Ingredients
          }
          // src={
          //   subscribtion?.duration === "30 days" ? ProductImage : Ingredients
          // }
          alt=""
          className={`${
            ["6 months", "3 months", "1 month"].includes(subscribtion?.duration)
              ? "!t-h-[155px] !t-w-[155px] object-contain"
              : "!t-h-[155px] !t-w-[155px] object-contain"
          } t-w-[40%]`}
        />
      </div>
      <div className="t-flex t-gap-2 t-justify-center !t-items-stretch">
        {subs?.slice(0, 3).map((item, key) => (
          <button
            key={"mobile-sub-card-" + item.id}
            className={`t-flex-none !t-w-[30%] t-text-center t-py-1 t-rounded-lg t-border t-border-gry-200 t-text-sm t-font-bold ${
              subscribtion?.subId === item?.id
                ? "t-text-white t-bg-quaternary"
                : "t-text-gray-500 t-bg-white"
            }`}
            onClick={() => onSelectPlan(item)}
          >
            {item.duration === "30 days"
              ? t(TryOnce)
              : item.duration === "3 months"
              ? t(MonthlyPlan)
              : t(ThreeMonthPlan)}
            {item.duration === "6 months" && (
              <p
                className={`t-m-0 t-text-xs !t-font-[600] ${
                  subscribtion?.subId === item?.id
                    ? "t-text-primary"
                    : "t-text-quaternary"
                }`}
              >
                {t(Save30Percent)}
              </p>
            )}
            {item.duration === "3 months" && (
              <p
                className={`t-m-0 t-text-xs !t-font-[600] ${
                  subscribtion?.subId === item?.id
                    ? "t-text-primary"
                    : "t-text-quaternary"
                }`}
              >
                {t(Save15Percent)}
              </p>
            )}
          </button>
        ))}
      </div>
      <p className="t-m-0 t-text-2xl t-font-[700] t-text-secondary t-text-center">
        {subscribtion?.duration === "6 months"
          ? Math.round(parseFloat(totals["sixMonths"]))
          : subscribtion?.duration === "3 months"
          ? Math.round(parseFloat(totals["threeMonths"]))
          : Math.round(parseFloat(totals["oneMonth"]))}
        € {subscribtion?.duration === "30 days" ? "" : "/" + t(Month)}
      </p>
      <p className="t-text-gray-400 t-text-base t-mb-0 t-text-center t-m-0">
        {subscribtion?.duration === "6 months"
          ? (Math.round(parseFloat(totals["sixMonths"])) / 30).toFixed(2) +
            "€ " +
            t(PerDay)
          : subscribtion?.duration === "3 months"
          ? (Math.round(parseFloat(totals["threeMonths"])) / 30).toFixed(2) +
            "€ " +
            t(PerDay)
          : (Math.round(parseFloat(totals["oneMonth"])) / 30).toFixed(2) +
            "€ " +
            t(PerDay)}
      </p>
      <p className="t-m-0 t-text-center">
        {subscribtion?.duration === "6 months"
          ? t(FreeShipping)
          : subscribtion?.duration === "3 months"
          ? t(FreeShipping)
          : t(FreeShippingInEu)}
      </p>
      {showBtn && (
        <button
          ref={boxRef}
          className="!t-text-sm !t-text-capitalize btn-custom-quaternary !t-py-2 !t-bg-secondary disabled:!t-bg-secondary hover:!t-text-white disabled:t-opacity-50 !t-rounded-lg"
          onClick={() => onSubmitData(paymentLink)}
          disabled={
            !paymentLink || Object.values(error).some((value) => value === true)
          }
        >
          {t(StartNowButton)}
        </button>
      )}
      <div className="t-flex t-flex-col !t-gap-1">
        <div className="t-flex t-items-start t-gap-2">
          <Icon name="tick" size="16" className="t-mt-1" />
          <p className="t-text-sm t-mb-0">
            {subscribtion?.duration === "6 months"
              ? t(Box6mLine1)
              : subscribtion?.duration === "3 months"
              ? t(Box3mLine1)
              : subscribtion?.duration === "30 days"
              ? t(t(TryOnceBox1))
              : t(Box1mLine1)}
          </p>
        </div>
        <div className="t-flex t-items-start t-gap-2">
          <Icon name="tick" size="16" className="t-mt-1" />
          <p className="t-text-sm t-mb-0">
            {subscribtion?.duration === "6 months"
              ? t(Box6mLine2)
              : subscribtion?.duration === "30 days"
              ? t(TryOnceBox2)
              : t(Box1mLine2)}
          </p>
        </div>
        {subscribtion?.duration !== "30 days" && (
          <div className="t-flex t-items-start t-gap-2">
            <Icon name="tick" size="16" className="t-mt-1" />
            <p className="t-text-sm t-mb-0">
              {subscribtion?.duration === "6 months"
                ? t(Box6mLine3)
                : t(Box1mLine3)}
            </p>
          </div>
        )}
        {subscribtion?.duration !== "30 days" && (
          <div className="t-flex t-items-start t-gap-2">
            <Icon name="tick" size="16" className="t-mt-1" />
            <p className="t-text-sm t-mb-0">
              {subscribtion?.duration === "6 months"
                ? t(Box6mLine4)
                : t(Box1mLine4)}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileSubscriptionCard;
