import React from "react";
import { useTranslation } from "react-i18next";
import {
  StressHeader1,
  NutirentsBodyNeed,
  StressNutrientsHeader,
  FormulatedByExperts,
  StressMagnesium,
  StressMagnesiumSecond,
  Magnesium,
  MagnesiumDesc,
  StressHeader,
  StressDescription,
  StressNutrientsHeader1,
  BVitamins,
  BVitaminsDesc,
  StressBVitamins,
  StressBVitaminsSecond,
  Ashwagandha,
  AshwagandhaDesc,
  StressAshwagandha,
  StressAshwagandhaSecond,
  VitaminC,
  VitaminCDesc,
  StressVitaminC,
  StressVitaminCSecond,
  SupportStress,
} from "../../Constants";
import ExpertSection from "../Customer/components/ExpertSection";
import MillionCombinations from "../Home/MillionCombinations";
import HighStandards from "../Home/HighStandards";
import ReviewsOverlay from "../Reviews";
import Unique from "../Home/Unique";
import MagnesiumImg from "../../assets/focusareas/magnesium-microgranules.webp";
import BVitaminsImg from "../../assets/focusareas/b-vitamins.png";
import VitaminCImg from "../../assets/focusareas/vitamin-C-microgranules.webp";
import AshwagandhaImg from "../../assets/focusareas/ashwagandha-microgranules.webp";
import BannerMobile from "./components/BannerMobile";
import Description from "./components/Description";
import NutrientsMobile from "./components/NutrientsMobile";
import News from "./components/News";
import GlutenFree from "./components/GlutenFree";
import Banner from "./components/Banner";
import Nutrients from "./components/Nutrients";
import UniqueWeb from "./components/UniqueWeb";
import GlutenFreeWeb from "./components/GlutenFreeWeb";

const Stress = () => {
  const { t } = useTranslation();

  const INGREDIENTS_DATA = [
    {
      title: t(Magnesium),
      desc: t(MagnesiumDesc),
      img: MagnesiumImg,
      details1: t(StressMagnesium),
      details2: t(StressMagnesiumSecond),
    },
    {
      title: t(BVitamins),
      desc: t(BVitaminsDesc),
      img: BVitaminsImg,
      details1: t(StressBVitamins),
      details2: t(StressBVitaminsSecond),
    },
    {
      title: t(Ashwagandha),
      desc: t(AshwagandhaDesc),
      img: AshwagandhaImg,
      details1: t(StressAshwagandha),
      details2: t(StressAshwagandhaSecond),
    },
    {
      title: t(VitaminC),
      desc: t(VitaminCDesc),
      img: VitaminCImg,
      details1: t(StressVitaminC),
      details2: t(StressVitaminCSecond),
    },
  ];

  return (
    <div>
      <BannerMobile heading1={t(StressHeader)} heading2={t(StressHeader1)} />
      <div className="t-hidden md:t-block">
        <Banner
          heading1={t(StressHeader)}
          heading2={t(StressHeader1)}
          desc1={t(StressDescription)}
          desc2=""
        />
        <Nutrients
          heading={t(NutirentsBodyNeed)}
          desc1={t(StressNutrientsHeader)}
          desc2={t(StressNutrientsHeader1)}
          data={INGREDIENTS_DATA}
        />

        <UniqueWeb />
        <GlutenFreeWeb />
      </div>
      <Description desc1={t(StressDescription)} desc2="" />
      <NutrientsMobile
        heading={t(NutirentsBodyNeed)}
        desc1={t(StressNutrientsHeader)}
        desc2={t(StressNutrientsHeader1)}
        data={INGREDIENTS_DATA}
      />
      <div className="md:t-hidden">
        <Unique isFocusArea heading={t(SupportStress)} />
      </div>
      <div>
        <MillionCombinations slug="/quiz" isFocusArea />
      </div>
      <section className="md:t-hidden t-flex t-items-center t-justify-center t-py-2 t-bg-gray-100 t-h-10">
        <ReviewsOverlay totalReviews={55} averageReviews={4.92} />
      </section>
      <div className="t-flex t-flex-col t-gap-4 t-p-4 t-max-w-[800px] !t-mx-auto">
        <h1 className="t-text-[24px] t-text-center">
          {t(FormulatedByExperts)}
        </h1>
        <ExpertSection />
      </div>
      <div>
        <HighStandards />
      </div>
      <GlutenFree />
      <News />
    </div>
  );
};

export default Stress;
